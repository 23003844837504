import forEach from "lodash/forEach";

function getErrors(data) {
  function getErrorsRecursive(errorData, curr) {
    if (typeof errorData === "object" && errorData !== null) {
      forEach(errorData, (d) => getErrorsRecursive(d, curr));
    } else {
      curr.push(errorData);
    }
  }
  const result = [];
  if (typeof data === "object" && data !== null) {
    getErrorsRecursive(data, result);
  }
  return result;
}

export default {
  setIsSubmitting(state, data) {
    state.isSubmitting = data;
  },
  setSubmitError(state, error) {
    const title = error?.response?.data?.title;
    const errors = error?.response?.data?.errors;

    if (title && errors) {
      state.submitError = {
        title,
        errors: getErrors(errors),
      };
    } else if (title) {
      state.submitError = title;
    } else {
      state.submitError = error;
    }
  },
  setSendEmailNotification(state, data) {
    state.sendEmailNotification = data;
  },
  setEmailSentTo(state, data) {
    state.emailSent = data?.milesBasedPayAcknowledge?.emailSent;
  },
};
