export default () => ({
  isFetching: false,
  fetchError: null,
  isSubmitting: false,
  submitError: {},
  hireDate: "",
  isNewYork: false,
  payCycle: "",
  payType: "",
  payRate: "",
  rateSheet: [],
});
