import axios from "axios";
import { getEnv } from "@/utils/util";

const api = axios.create({
  baseURL: `${getEnv("VUE_APP_ESS_API_URL")}/payroll`,
});

api.defaults.headers.common.Accept = "application/json";

export default {
  api,
  name() {
    return "employeeStockPurchaseAPI";
  },
  async fetchESPData() {
    return api.get("/employee-stock-purchase");
  },
  async submitESPSelection(payload) {
    return api.post("/employee-stock-purchase", payload);
  },
};
