import axios from "axios";
import { getEnv } from "@/utils/util";

const api = axios.create({
  baseURL: `${getEnv("VUE_APP_ESS_API_URL")}/ess`,
});

api.defaults.headers.common.Accept = "application/json";

export default {
  api,
  name() {
    return "essUserInfoAPI";
  },
  async fetchESSUserInfo() {
    return api.get("/userInfo");
  },
};
