import milesBasedPayAcknowledgeAPI from "@/modules/payroll/api/milesBasedPayAcknowledge";

export default {
  async submitAcknowledgement({ commit }, payload) {
    commit("setIsSubmitting", true);
    commit("setSubmitError", {});
    try {
      const { data } = await milesBasedPayAcknowledgeAPI.saveMilesBasePayAcknowledgement(payload);
      commit("setEmailSentTo", data);
      commit("setIsSubmitting", false);
    } catch (error) {
      console.log(error);
      if (error.response) {
        commit("setSubmitError", error.response.data);
      } else if (error.request) {
        commit("setSubmitError", {
          title: "Bad Request",
          status: "",
          detail: "Could not complete this request",
        });
      } else {
        commit("setSubmitError", "Unknown error");
      }
    } finally {
      commit("setIsSubmitting", false);
    }
  },
};
