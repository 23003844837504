import employeeStockPurchaseAPI from "@/modules/payroll/api/employeeStockPurchase";

export default {
  async fetchESPData({ commit }) {
    commit("setIsFetching", true);
    commit("setFetchError", null);

    try {
      const { data } = await employeeStockPurchaseAPI.fetchESPData();

      const { suppress } = data.employeeStockPurchase;

      commit("setAllowSharing", suppress);
    } catch (error) {
      if (error.response) {
        commit("setFetchError", error.response.data);
      } else if (error.request) {
        commit("setFetchError", {
          title: "Bad Request",
          status: "",
          detail: "Could not complete this request",
        });
      } else {
        commit("setFetchError", "Unknown error");
      }
    } finally {
      commit("setIsFetching", false);
    }
  },
  async submitESPSelection({ commit }, payload) {
    commit("setIsSubmitting", true);
    commit("setSubmitError", {});

    try {
      const { data } = await employeeStockPurchaseAPI.submitESPSelection(payload);

      const { suppress } = data.employeeStockPurchase;

      commit("setAllowSharing", suppress);
    } catch (error) {
      if (error.response) {
        commit("setSubmitError", error.response.data);
      } else if (error.request) {
        commit("setSubmitError", {
          title: "Bad Request",
          status: "",
          detail: "Could not complete this request",
        });
      } else {
        commit("setSubmitError", "Unknown error");
      }
    } finally {
      commit("setIsSubmitting", false);
    }
  },
};
