import axios from "axios";
import { getEnv } from "@/utils/util";

const api = axios.create({
  baseURL: `${getEnv("VUE_APP_ESS_API_URL")}/drivers`,
});

api.defaults.headers.common.Accept = "application/json";

export default {
  api,
  name() {
    return "smokingPreferenceAPI";
  },
  async fetchSmokingPreference() {
    return api.get("/smoking-preference");
  },
  async updateSmokingPreference(payload) {
    return api.put("/smoking-preference", payload);
  },
};
