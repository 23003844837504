<template>
  <pageManager :isLoading="isFetching" :error="fetchError" :isEmpty="false">
    <v-container>
      <v-card elevation="2">
        <v-card-title class="headline white--text primary font-weight-light">
          California Meal & Break Waiver Form
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-spacer />
            <v-col cols="12" md="10" lg="8">
              <fieldset class="text-center mt-4 mb-4">
                <v-row>
                  <v-spacer />
                  <v-col cols="12" sm="3">
                    <display-field :value="employeeName" label="Employee Name" />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <display-field :value="employeeNumber" label="Employee #" />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <display-field :value="employeeSSNLast4" label="Last 4 SSN" />
                  </v-col>
                  <v-spacer />
                </v-row>
              </fieldset>
              <p>
                I understand that according to Swift's policies, I am provided an unpaid off-duty meal period of at
                least 30 minutes if I work at least five hours per work day. I understand that I must take this meal
                period within the first five hours of my workday. However, I understand that if my workday can be
                completed in six hours or less, I may waive the meal period by signing a waiver.
              </p>
              <p>
                I understand that according to Swift's policies, if my workday is longer than ten hours, I am provided a
                second unpaid off-duty meal period of at least thirty. However, I understand that if I work less than
                twelve hours in a day, I may waive the second meal period so long as I received the first meal period.
              </p>
              <p>
                I further understand that according to Swift's policies, if I work more than three hours per workday, I
                am entitled to a ten minute, paid off-duty rest period for each four hours worked, to be taken in the
                middle of each four hour period of work, to the extent possible.
              </p>
              <p>
                I understand that I am eligible for one or more unpaid meal period(s) and paid rest period(s) each
                workday. By signing below I am certifying that: [CHECK THE APPROPRIATE BOX(es)]
              </p>
              <div class="form">
                <v-row class="align-center">
                  <v-col cols="1">
                    <v-checkbox v-model="firstMealWaiver" :disabled="noWaiver" />
                  </v-col>
                  <v-col cols="11">
                    I am waiving my right to my first meal period. I understand that this waiver is valid so long as I
                    do not work more than six hours on one workday. I also understand that I may revoke this waiver at
                    any time by submitting written notice to the Payroll Department or email
                    <a href="mailto:nondriverpayroll@swifttrans.com">nondriverpayroll@swifttrans.com</a>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="1">
                    <v-checkbox v-model="secondMealWaiver" :disabled="noWaiver" />
                  </v-col>
                  <v-col cols="11">
                    I am waiving my right to my second meal period. I understand that this waiver is valid so long as I
                    do not waive my first meal period the same day and do not work more than twelve hours in one
                    workday. I also understand that I may revoke this waiver at any time by submitting written notice to
                    the Payroll Department or email
                    <a href="mailto:nondriverpayroll@swifttrans.com">nondriverpayroll@swifttrans.com</a>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="1">
                    <v-checkbox v-model="onJobMeal" :disabled="noWaiver" />
                  </v-col>
                  <v-col cols="11">
                    I agree that if the requirements of my job necessarily prevent me from taking an off-duty meal
                    period, I will take an on-the-job paid 30 minute meal period. I also understand that I may revoke
                    this waiver at any time by submitting written notice to the Payroll Department or email
                    <a href="mailto:nondriverpayroll@swifttrans.com">nondriverpayroll@swifttrans.com</a>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="1">
                    <v-checkbox v-model="noWaiver" :disabled="firstMealWaiver || secondMealWaiver || onJobMeal" />
                  </v-col>
                  <v-col cols="11">
                    I am not waiving any meal period.
                  </v-col>
                </v-row>
              </div>
              <div class="signature-required" v-if="!isFormSigned">Electronic Signature Required</div>
              <v-checkbox
                v-model="isFormSigned"
                label="By checking here, I am providing an electronic signature confirming I have read through the
                  California Meal & Break Waiver Form. I understand the electronic signature is binding in the same
                  manner as a handwritten signature."
              />
              <v-checkbox
                :disabled="!hasNotificationEmail"
                v-model="sendEmailNotification"
                :label="'Send me an email confirmation to: ' + notificationEmail"
              />
              <v-alert
                v-show="!hasNotificationEmail"
                outlined
                dense
                text
                type="warning"
              >
                <v-row>
                  <v-col cols="12" md="7">
                    You do not have a notification email on file. You can continue without an email confirmation, or
                    go to My Information to set a personal email address.
                  </v-col>
                  <v-col cols="12" md="5" class="d-flex align-center justify-center">
                    <v-btn color="warning" @click="openMyInfo()">Edit My Information</v-btn>
                  </v-col>
                </v-row>
              </v-alert>
              <v-row>
                <v-spacer />
                <v-col cols="6" sm="4" xl="3">
                  <v-btn
                    class="action-button"
                    color="primary"
                    :disabled="!isFormSigned || (!firstMealWaiver && !secondMealWaiver && !onJobMeal && !noWaiver)"
                    @click="submitWaivers()"
                  >
                    Accept
                  </v-btn>
                </v-col>
                <v-col cols="6" sm="4" xl="3">
                  <v-btn class="action-button" color="primary" @click="returnToDashboard()">Discard</v-btn>
                </v-col>
                <v-spacer />
              </v-row>
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <submitDialog :propIsSubmitting="isSubmitting" :propSubmitError="submitError" @clearErrors="clearErrors()" />
    <successDialog :message="successDialog.message" :show="successDialog.show" @close="closeSuccessDialog()" />
  </pageManager>
</template>

<script>
import Component from "vue-class-component";
import pageManager from "@/components/pageManager.vue";
import successDialog from "@/components/successDialog.vue";
import submitDialog from "@/components/submitDialog.vue";
import DisplayField from "@/components/displayField.vue";
import { Mixins } from "vue-property-decorator";
import modulePageMixin from "@/utils/mixins";
import { namespace } from "vuex-class";
import _ from "lodash";

const waiverStore = namespace("payroll/californiaMealBreakWaiver");
const authStore = namespace("auth");

@Component({
  components: {
    DisplayField, submitDialog, successDialog, pageManager,
  },
})
export default class CaliforniaMealBreakWaiver extends Mixins(modulePageMixin) {
  @waiverStore.Action("fetchWaiverAccess") fetchWaiverAccess;

  @waiverStore.Action("submitWaiverSelections") submitWaiverSelections;

  @waiverStore.Getter("fetchError") fetchError;

  @waiverStore.Getter("isSubmitting") isSubmitting;

  @waiverStore.Getter("submitError") submitError;

  @waiverStore.Mutation("setSubmitError") setSubmitError;

  @authStore.Action("reloadUserInfo") reloadUserInfo;

  isFetching = false;

  firstMealWaiver = false;

  secondMealWaiver = false;

  onJobMeal = false;

  noWaiver = false;

  sendEmailNotification = false;

  isFormSigned = false;

  successDialog = {
    show: false,
    message: "Successfully Submitted California Meal & Break Waiver Form!",
  };

  get employeeName() {
    return this.currentUserFullName;
  }

  get employeeNumber() {
    return this.currentUserEmployeeId;
  }

  get employeeSSNLast4() {
    return this.currentUserSSNLast4;
  }

  returnToDashboard() {
    this.$router.push("/");
  }

  openMyInfo() {
    this.$router.push("/personal-info/my-information");
  }

  clearErrors() {
    this.setSubmitError({});
  }

  showSuccessDialog() {
    this.successDialog.show = _.isEmpty(this.submitError);
  }

  closeSuccessDialog() {
    this.successDialog.show = false;
    this.returnToDashboard();
  }

  async submitWaivers() {
    const payload = {
      signed: this.isFormSigned,
      firstMealWaiver: this.firstMealWaiver,
      secondMealWaiver: this.secondMealWaiver,
      onJobMeal: this.onJobMeal,
      noWaiver: this.noWaiver,
      sendEmailNotification: this.sendEmailNotification,
    };

    await this.submitWaiverSelections(payload);

    this.showSuccessDialog();
  }

  async mounted() {
    this.isFetching = true;

    await this.reloadUserInfo();
    await this.fetchWaiverAccess();

    this.isFetching = false;

    this.sendEmailNotification = this.hasNotificationEmail;
  }
}
</script>

<style scoped>
.form {
    border: 1px solid;
    padding: 12px;
}

.signature-required {
    font-size: 20px;
    color: #ff0000;
    margin-top: 16px;
}

.action-button {
    width: 100%;
}
</style>
