import axios from "axios";
import { getEnv } from "@/utils/util";

const api = axios.create({
  baseURL: `${getEnv("VUE_APP_ESS_API_URL")}/payroll`,
});

api.defaults.headers.common.Accept = "application/json";

export default {
  api,
  name() {
    return "newYorkAcknowledgePayRateAPI";
  },
  async fetchNAPRAccess() {
    return api.get("/new-york-acknowledge-pay-rate");
  },
  async submitNAPRAcknowledgement(payload) {
    return api.post("/new-york-acknowledge-pay-rate", payload);
  },
};
