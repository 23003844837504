<template>
  <v-container fluid class="emergency-contact-page">
    <pageManager :isLoading="isLoading" :isEmpty="false" :error="error">
      <template #loading>
        <div class="loader">
          <v-progress-circular indeterminate color="accent" />
        </div>
      </template>
      <v-alert
        dense
        color="blue lighten-2"
        type="info"
        prominent
        v-if="hasECUnsavedChanges"
      >
        <v-row align="center">
          <v-col class="grow">You currently have unsaved changes</v-col>
          <v-col class="shrink">
            <v-btn color="blue darken-3" @click="save">Save Changes</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-for="contact in contacts"
          :key="contact.feId ? contact.feId : contact.sequenceNumber"
        >
          <Contact ref="contactCard" :contact="contact" @save="save" />
        </v-col>
      </v-row>
    </pageManager>
    <FloatingControls
      @save="save"
      @add="addContactCard"
      @discard="discardChanges"
      :actions="actions"
    />
    <v-overlay v-if="isSaving" color="secondary">
      <v-progress-circular indeterminate color="accent" />
    </v-overlay>
    <unsavedChangesDialog
      :show="navigateDialog"
      @cancelNavigation="cancelNavigation"
      @continueNavigation="continueNavigation"
    />
    <infoDialog
      v-if="showInfoDialog"
      :propInfo="savingInfo"
      @clearInfo="clearInfo"
    />
  </v-container>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from "vuex";
import FloatingControls from "@/components/floating-controls.vue";
import unsavedChangesDialog from "@/components/unsaved-changes-dialog.vue";
import infoDialog from "@/components/info-dialog.vue";
import _ from "lodash";
import { getEnv } from "@/utils/util";
import pageManager from "@/components/pageManager.vue";
import modulePageMixin from "@/utils/mixins";
import Contact from "./contact.vue";

export default {
  name: "EmergencyContact",
  mixins: [modulePageMixin],
  components: {
    Contact,
    FloatingControls,
    unsavedChangesDialog,
    infoDialog,
    pageManager,
  },
  data() {
    return {
      navigateTo: null,
      navigateDialog: false,
      showInfoDialog: false,
      actions: [
        {
          event: "add",
          color: "success",
          icon: "mdi-plus",
          name: "Add Contact",
        },
        {
          event: "save",
          color: "success",
          icon: "mdi-content-save-all",
          name: "Save",
        },
        {
          event: "discard",
          color: "accent",
          icon: "mdi-close",
          name: "Discard Changes",
        },
      ],
    };
  },
  computed: {
    ...mapState("personalInfo/emergencyContacts", {
      contacts: "workingContacts",
      initialContacts: "initialContacts",
      isLoading: "isLoading",
      isSaving: "isSaving",
      error: "error",
      savingError: "savingError",
      savingInfo: "savingInfo",
    }),
    ...mapGetters("auth", ["employeeId", "employerId"]),
    ...mapGetters("personalInfo/emergencyContacts", ["hasECUnsavedChanges"]),
  },
  async mounted() {
    await this.$store.dispatch(
      "personalInfo/emergencyContacts/fetchContacts",
      this.employeeId,
    );

    if (this.isSwift) {
      this.actions.shift();
    }
  },
  methods: {
    ...mapMutations("personalInfo/emergencyContacts", ["addContact"]),
    ...mapActions("personalInfo/emergencyContacts", [
      "saveContacts",
      "discardChanges",
    ]),
    addContactCard() {
      // TODO: make the toasted notifications global
      if (this.contacts.length >= getEnv("VUE_APP_MAX_EMERGENCY_CONTACTS")) {
        this.$toasted.global.essError({
          message: "You have the max number of Contacts.",
        });

        return;
      }
      this.addContact({
        employeeId: this.employeeId,
        employerId: this.employerId,
      });
    },
    async save() {
      this.$refs.contactCard.forEach((contact) => contact.validate());
      let allValid = true;
      this.$refs.contactCard.forEach((contact) => {
        if (!contact.valid) {
          allValid = false;
        }
      });
      if (!allValid) {
        this.$toasted.global.essError({
          message: "Please fix errors prior to saving.",
        });
      }

      const primaryCount = this.contacts.filter(
        (contact) => contact.primaryContact,
      ).length;
      if (primaryCount !== 1) {
        this.$toasted.global.essError({
          message: "Must have one primary contact to save.",
        });
      }

      const validNumber = this.contacts.length <= getEnv("VUE_APP_MAX_EMERGENCY_CONTACTS");
      if (!validNumber) {
        this.$toasted.global.essError({
          message: "You have the max number of Contacts.",
        });
      }

      if (allValid && primaryCount === 1 && validNumber) {
        await this.$store.dispatch(
          "personalInfo/emergencyContacts/saveContacts",
        );

        if (!_.isEmpty(this.savingInfo)) {
          this.showInfoDialog = true;
        }

        if (this.savingError?.errors) {
          Object.values(this.savingError.errors).forEach((error) => {
            this.$toasted.global.essError({
              message: error,
            });
          });
        } else if (this.savingError) {
          this.$toasted.global.essError({
            message: this.savingError,
          });
        }

        if (!this.savingError && _.isEmpty(this.savingInfo)) {
          this.$toasted.success("Your changes have been successfully saved!", {
            duration: 2000,
          });
        }
      }
    },
    clearInfo() {
      this.showInfoDialog = false;
      // return to home
      this.$router.push("/");
    },
    continueNavigation() {
      this.navigateDialog = false;
      // returns to beforeRouteLeave and executes next function
      this.$router.push(this.navigateTo);
    },
    cancelNavigation() {
      this.navigateDialog = false;
      this.navigateTo = null;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasECUnsavedChanges) {
      if (this.navigateTo) {
        next();
      } else {
        this.navigateTo = to;
        this.navigateDialog = true;
      }
    } else {
      next();
    }
  },
  page: {
    title: "Emergency Contact",
    meta: [{ name: "description", content: "The Emergency Contact page." }],
  },
  watch: {
    hasECUnsavedChanges(val) {
      if (val) {
        window.onbeforeunload = () => true;
      } else {
        window.onbeforeunload = null;
      }
    },
  },
};
</script>
