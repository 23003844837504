import axios from "axios";
import { getEnv } from "@/utils/util";

const api = axios.create({
  baseURL: `${getEnv("VUE_APP_ESS_API_URL")}/drivers`,
});

api.defaults.headers.common.Accept = "application/json";

export default {
  api,
  name() {
    return "driverTextAPI";
  },
  async fetchDriverTextData() {
    return api.get("/driver-text");
  },
  async submitDriverTextSelections(payload) {
    return api.patch("/driver-text", payload);
  },
};
