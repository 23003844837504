import directDepositAPI from "@/modules/payroll/api/directDeposit";

export default {
  async fetchDeposits({ commit }, employeeId) {
    commit("setIsLoading", true);
    commit("setError", null);
    try {
      const { data } = await directDepositAPI.getDirectDeposits(employeeId);
      commit("setInitialDeposits", data?._embedded?.direct_deposits ?? []);
      commit("setDeposits");
    } catch (error) {
      if (error.response) {
        commit("setSubmitError", error.response.data);
      } else if (error.request) {
        commit("setSubmitError", {
          title: "Bad Request",
          status: "",
          detail: "Could not complete this request",
        });
      } else {
        commit("setSubmitError", "Unknown error");
      }
    } finally {
      commit("setIsLoading", false);
    }
  },
  async saveDeposits({ commit, state }) {
    commit("setIsSaving", true);
    commit("setSubmitError", {});
    try {
      const { data } = await directDepositAPI.saveDirectDeposits(state.deposits);
      commit("setInitialDeposits", data?._embedded?.direct_deposits ?? []);
      commit("setDeposits");
    } catch (error) {
      if (error.response) {
        commit("setSubmitError", error.response.data);
      } else if (error.request) {
        commit("setSubmitError", {
          title: "Bad Request",
          status: "",
          detail: "Could not complete this request",
        });
      } else {
        commit("setSubmitError", "Unknown error");
      }
    } finally {
      commit("setIsSaving", false);
    }
  },
};
