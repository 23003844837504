<template>
  <v-dialog v-model="show" scrollable :max-width="maxWidth" persistent>
    <v-card>
      <v-card-title>
        <v-icon class="mr-2">mdi-check-circle-outline</v-icon>
        {{ title }}
      </v-card-title>
      <v-card-text v-if="message !== ''" style="max-height: 400px">
        <h2 class="font-weight-medium mt-2">{{ message }}</h2>
      </v-card-text>
      <v-card-text v-if="messageHTML !== ''" style="max-height: 400px">
        <div v-html="messageHTML" class="font-weight-medium mt-2" />
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="hasSecondaryAction"
          color="blue darken-1"
          text
          @click="$emit('secondActionClick')"
        >
          {{ secondActionLabel }}
        </v-btn>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="$emit('close')"> Ok </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SuccessDialog",
  props: {
    title: { type: String, required: false, default: "Success" },
    maxWidth: { type: String, default: "550" },
    message: { type: String, required: true },
    messageHTML: { type: String, required: false, default: "" },
    show: { type: Boolean, required: true },
    showSecondAction: { type: Boolean, required: false, default: false },
    secondActionLabel: { type: String, default: "" },
  },
  computed: {
    hasSecondaryAction() {
      return this.showSecondAction;
    },
  },
};
</script>
