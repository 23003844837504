<template>
  <div>
    <charities-disclaimer v-if="!hasAcceptedDisclaimer" @accept="acceptDisclaimer()" />
    <charities-form v-else />
  </div>
</template>

<script>
import Component from "vue-class-component";
import CharitiesDisclaimer from "@/modules/payroll/charities/components/charities-disclaimer.vue";
import CharitiesForm from "@/modules/payroll/charities/components/charities-form.vue";

@Component({
  name: "Charities",
  components: {
    CharitiesForm,
    CharitiesDisclaimer,
  },
})
export default class Charities {
  hasAcceptedDisclaimer = false;

  acceptDisclaimer() {
    this.hasAcceptedDisclaimer = true;
  }
}
</script>
