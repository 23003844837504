import axios from "axios";
import { getEnv } from "@/utils/util";

const api = axios.create({
  baseURL: `${getEnv("VUE_APP_ESS_API_URL")}/payroll`,
});

api.defaults.headers.common.Accept = "application/json";

export default {
  api,
  name() {
    return "californiaMealBreakWaiverAPI";
  },
  async fetchWaiverAccess() {
    return api.get("/california-meal-break-waiver");
  },
  async submitWaiverSelections(payload) {
    return api.post("/california-meal-break-waiver", payload);
  },
};
