export default [
  {
    path: "/drivers",
    name: "drivers",
    component: "modules/drivers",
    meta: {
      title: "Drivers",
      hideFromNav: false,
      icon: "mdi-truck",
    },
    excludeUsers: ["isNonDriver"],
    children: [
      {
        name: "drivers:driverText",
        path: "driverText",
        component: "modules/drivers/driverText",
        meta: {
          title: "Driver Text Opt In/Out",
          hideFromNav: false,
          icon: "mdi-cellphone-message",
        },
      },
      {
        name: "drivers:smokingPreference",
        path: "smokingPreference",
        component: "modules/drivers/smokingPreference",
        meta: {
          title: "Smoker/Non-Smoker Status",
          hideFromNav: false,
          icon: "mdi-smoking",
        },
      },
    ],
  },
];
