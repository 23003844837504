<template>
  <pageManager :isLoading="isFetching" :error="fetchError" :isEmpty="false">
    <v-container>
      <v-card elevation="2" :loading="isFetching ? 'grey lighten-2' : null">
        <v-card-title class="headline white--text primary font-weight-light">
          {{ title }}
        </v-card-title>
        <v-card-text class="form">
          <v-row>
            <v-spacer />
            <v-col cols="12" lg="8">
              <fieldset>
                <legend class="fieldset-title">Current Information</legend>
                <v-row class="current-info">
                  <v-spacer />
                  <v-col cols="12" sm="3">
                    <display-field :value="employeeName" label="Employee Name" />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <display-field :value="employeeNumber" label="Employee #" />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <display-field :value="employeeSSNLast4" label="Last 4 SSN" />
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div class="current-status" :style="{ color: statusColor }">{{ statusMessage }}</div>
                  </v-col>
                </v-row>
              </fieldset>
              <v-row>
                <v-spacer />
                <v-col cols="12" md="10" lg="8">
                  <div class="program-description">
                    As part of the implementation of the Swift Employee Stock Purchase Program, Swift has partnered with
                    Merrill Lynch to provide record keeping, brokerage and related services for the Swift Transportation
                    Company 2012 Employee Stock Purchase Plan. If you elect to participate in the Employee Stock
                    Purchase Plan, you will be able to manage your stock purchased through the Employee Stock Purchase
                    Plan easily and conveniently through this partnership. As part of the initial and on-going
                    enrollment process, certain of your employee demographic information maintained by Swift will be
                    shared and transferred between Swift and Merrill Lynch.
                  </div>
                  <v-checkbox
                    v-model="isFormSigned"
                    label="By checking here, I am providing an electronic signature confirming I have read through the
                  information provided above. I understand the electronic signature is binding in the same manner as a
                  handwritten signature."
                  />
                  <div class="signature-required" v-if="!isFormSigned">Electronic Signature Required</div>
                  <v-btn
                    class="submit-button"
                    :color="buttonColor"
                    outlined
                    :disabled="!isFormSigned"
                    @click="submit()"
                  >
                    {{ buttonAction }}
                  </v-btn>
                  <div class="note">{{ note }}</div>
                </v-col>
                <v-spacer />
              </v-row>
            </v-col>
            <v-spacer />
          </v-row>

        </v-card-text>
      </v-card>
      <submitDialog :propIsSubmitting="isSubmitting" :propSubmitError="submitError" @clearErrors="clearErrors()" />
      <successDialog :message="successDialog.message" :show="successDialog.show" @close="closeSuccessDialog()" />
    </v-container>
  </pageManager>
</template>

<script>
import Component from "vue-class-component";
import pageManager from "@/components/pageManager.vue";
import DisplayField from "@/components/displayField.vue";
import { Mixins } from "vue-property-decorator";
import modulePageMixin from "@/utils/mixins";
import { namespace } from "vuex-class";
import submitDialog from "@/components/submitDialog.vue";
import successDialog from "@/components/successDialog.vue";
import _ from "lodash";

const espStore = namespace("payroll/employeeStockPurchase");

@Component({
  name: "EmployeeStockPurchase",
  components: {
    successDialog,
    submitDialog,
    DisplayField,
    pageManager,
  },
})
export default class EmployeeStockPurchase extends Mixins(modulePageMixin) {
  @espStore.Action("fetchESPData") fetchESPData;

  @espStore.Action("submitESPSelection") submitESPSelection;

  @espStore.Getter("isFetching") isFetching;

  @espStore.Getter("fetchError") fetchError;

  @espStore.Getter("isSubmitting") isSubmitting;

  @espStore.Getter("submitError") submitError;

  @espStore.Getter("allowSharing") allowSharing;

  @espStore.Mutation("setSubmitError") setSubmitError;

  title = "Employee Stock Purchase Program";

  isFormSigned = false;

  successDialog = {
    show: false,
    message: "Successfully Updated ESPP Selection!",
  };

  get employeeName() {
    return this.currentUserFullName;
  }

  get employeeNumber() {
    return this.currentUserEmployeeId;
  }

  get employeeSSNLast4() {
    return this.currentUserSSNLast4;
  }

  get statusMessage() {
    return this.allowSharing
      ? "Swift is allowed to share my information"
      : "Swift is not allowed to share my information";
  }

  get statusColor() {
    return this.allowSharing ? "#006400" : "#ff0000";
  }

  get buttonAction() {
    return this.allowSharing
      ? "CLICK HERE TO DENY SWIFT FROM SHARING MY INFORMATION FOR THE EMPLOYEE STOCK PURCHASE PROGRAM"
      : "CLICK HERE TO APPROVE OF SWIFT SHARING MY INFORMATION FOR THE EMPLOYEE STOCK PURCHASE PROGRAM";
  }

  get buttonColor() {
    return this.allowSharing ? "#ff0000" : "#006400";
  }

  get note() {
    return this.allowSharing
      ? "NOTE: By selecting this option, you will not be able to participate in ESPP. "
      + "If you are currently enrolled and select this option, your participation will be cancelled "
      + "and any funds currently withheld will be refunded on your next scheduled payroll/paycheck."
      : "NOTE: If you select this option, you will be able to participate in ESPP. "
      + "You will need to enroll in the program during the next quarterly enrollment period.";
  }

  async mounted() {
    await this.fetchESPData();
  }

  async submit() {
    const payload = {
      signed: this.isFormSigned,
      suppress: this.allowSharing,
    };

    await this.submitESPSelection(payload);

    this.showSuccessDialog();
  }

  clearErrors() {
    this.setSubmitError({});
  }

  showSuccessDialog() {
    this.successDialog.show = _.isEmpty(this.submitError);
  }

  closeSuccessDialog() {
    this.isFormSigned = false;
    this.successDialog.show = false;
    this.$router.push("/");
  }
}
</script>

<style scoped>
.form {
    text-align: center;
}

.fieldset-title {
    text-align: left;
}

.current-info {
    font-size: 16px;
}

.current-status {
    font-size: 24px;
    font-weight: bold;
}

.program-description {
    font-size: 16px;
    font-weight: bold;
    margin: 32px 0 16px 0;
}

.signature-required {
    text-align: left;
    font-size: 20px;
    color: #ff0000;
    margin-bottom: 16px;
}

.submit-button {
    width: 75%;
    height: auto !important;
    white-space: normal;
    &.v-btn:not(.v-btn--round).v-size--default {
        padding: 10px 16px;
    }
    .v-btn__content {
        flex: 1 0 fit-content;
    }
}

.note {
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
}
</style>
