export default [
  {
    moduleId: "payroll",
    moduleName: "payroll",
    metaInfo: {
      title: "Payroll",
      meta: [
        {
          name: "ESS Payroll",
          content: "Payroll",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
    api: [{}],
  },
  {
    moduleId: "payroll:directDeposit",
    parent: "payroll",
    moduleName: "directDeposit",
    metaInfo: {
      title: "Direct Deposit",
      meta: [
        {
          name: "directDeposit",
          content: "Direct Deposit",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
  {
    moduleId: "payroll:taxForm",
    parent: "payroll",
    moduleName: "taxForm",
    metaInfo: {
      title: "State Tax",
      meta: [
        {
          name: "taxForm",
          content: "State Tax",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
  {
    moduleId: "payroll:emailPreferences",
    parent: "payroll",
    moduleName: "emailPreferences",
    metaInfo: {
      title: "Email Preferences",
      meta: [
        {
          name: "emailPreferences",
          content: "Email Preferences",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
  {
    moduleId: "payroll:milesBasedPayAcknowledge",
    parent: "payroll",
    moduleName: "milesBasedPayAcknowledge",
    metaInfo: {
      title: "Miles Based Pay Acknowledgement",
      meta: [
        {
          name: "milesBasedPayAcknowledge",
          content: "Miles Based Pay Acknowledgement",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
  {
    moduleId: "payroll:employeeStockPurchase",
    parent: "payroll",
    moduleName: "employeeStockPurchase",
    metaInfo: {
      title: "Employee Stock Purchase Program",
      meta: [
        {
          name: "employeeStockPurchase",
          content: "Employee Stock Purchase Program",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
  {
    moduleId: "payroll:charities",
    parent: "payroll",
    moduleName: "charities",
    metaInfo: {
      title: "Charities",
      meta: [
        {
          name: "charities",
          content: "Charities",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
  {
    moduleId: "payroll:californiaMealBreakWaiver",
    parent: "payroll",
    moduleName: "californiaMealBreakWaiver",
    metaInfo: {
      title: "California Meal & Break Waiver",
      meta: [
        {
          name: "californiaMealBreakWaiver",
          content: "California Meal & Break Waiver",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
  {
    moduleId: "payroll:newYorkAcknowledgePayRate",
    parent: "payroll",
    moduleName: "newYorkAcknowledgePayRate",
    metaInfo: {
      title: "Notice and Acknowledgement of Pay Rate and Payday",
      meta: [
        {
          name: "newYorkAcknowledgePayRate",
          content: "Notice and Acknowledgement of Pay Rate and Payday",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
];
