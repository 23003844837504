<template>
  <div class="direct-deposit">
    <v-container>
      <v-form ref="form" v-model="valid">
        <v-alert dense color="blue lighten-2" type="info" prominent v-if="isFirstMovement && deposits.length === 1">
          Currently your entire net pay is not being split. Would you like to entered one or more splits below?
        </v-alert>
        <v-row>
          <v-col cols="12" md="12" class="headline grey--text">
            {{ depositeHeader }}
          </v-col>
        </v-row>
        <v-row v-if="isFuelCard">
          <v-fade-transition>
            <DeleteOverlay v-if="isDeleting" @cancel="isDeleting = false" @delete="confirmDelete" />
          </v-fade-transition>
          <v-col cols="12" md="3">
            <v-text-field
              ref="amount"
              v-if="deposit.depositType === 'F'"
              label="Dollar Amount"
              v-model="amountToFormat"
              :rules="amountRules"
              prefix="$"
              v-money="money"
              @change="formattingAmount"
            />
            <v-text-field
              ref="amount"
              v-else-if="deposit.depositType === 'P'"
              type="number"
              min="1"
              max="99"
              label="Percentage"
              :value="deposit.amount"
              :rules="amountRules"
              suffix="%"
              @input="
                (val) =>
                  setDepositAmount({
                    feId: deposit.feId,
                    amount: val,
                  })
              "
              class="percentage-field"
            />
            <v-text-field v-else label="Amount" value="Entire Net Pay" readonly />
          </v-col>
          <v-col cols="12" md="3">
            <v-btn v-if="deposits.length > 0" @click="isDeleting = true" text icon>
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-fade-transition>
            <DeleteOverlay v-if="isDeleting" @cancel="isDeleting = false" @delete="confirmDelete" />
          </v-fade-transition>
          <v-col cols="12" md="2">
            <v-text-field
              ref="routingNumber"
              label="Routing Number"
              :value="deposit.routingNumber"
              :rules="[required, routingNumber]"
              @input="
                (val) =>
                  setDepositRoutingNumber({
                    feId: deposit.feId,
                    number: val,
                  })
              "
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-if="!isFuelCard"
              ref="bankName"
              :value="deposit.bankName"
              label="Bank Name"
              :rules="[required]"
              @input="
                (val) =>
                  setDepositBankName({
                    feId: deposit.feId,
                    name: val,
                  })
              "
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-if="!isFuelCard"
              ref="accountNumber"
              label="Account Number"
              :value="deposit.accountNumber"
              :rules="[required]"
              @input="
                (val) =>
                  setDepositAccountNumber({
                    feId: deposit.feId,
                    number: val,
                  })
              "
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              ref="amount"
              v-if="deposit.depositType === 'F'"
              label="Dollar Amount"
              v-model="amountToFormat"
              prefix="$"
              :rules="amountRules"
              v-money="money"
              @change="formattingAmount"
            />
            <v-text-field
              ref="amount"
              v-else-if="deposit.depositType === 'P'"
              type="number"
              min="1"
              max="99"
              label="Percentage"
              :value="deposit.amount"
              :rules="amountRules"
              suffix="%"
              @input="
                (val) =>
                  setDepositAmount({
                    feId: deposit.feId,
                    amount: val,
                  })
              "
              class="percentage-field"
            />
            <v-text-field v-else label="Amount" value="Entire Net Pay" readonly />
          </v-col>
          <v-col cols="12" md="2">
            <v-btn v-if="deposits.length > 0" @click="isDeleting = true" text icon>
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import DeleteOverlay from "@/components/delete-overlay.vue";
import { mapMutations, mapGetters, mapState } from "vuex";
import { VMoney } from "v-money";
import validators from "@/utils/validators";

export default {
  components: {
    DeleteOverlay,
  },
  directives: { money: VMoney },
  props: {
    deposit: { type: Object, required: true },
    // We are using this because we are avoiding race conditions when formatting the value
    unformattedAmount: { type: Object, required: true },
  },
  data() {
    return {
      isDeleting: false,
      valid: false,
      amountValidation: /^[1-9]\d*(((\d{3}){1})?(\.\d{0,2})?)$/,
      twoDecimalPlaces: /\.\d{2}$/g,
      oneDecimalPlace: /\.\d{1}$/g,
      noDecimalPlacesWithDecimal: /\.\d{0}$/g,
      amountToFormat: this.unformattedAmount.amount,
      money: {
        decimal: ".",
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["isDriver"]),
    ...mapState("payroll/directDeposit", ["isFirstMovement", "deposits"]),
    isChecking() {
      return this.deposit.accountType === "C";
    },
    isFuelCard() {
      return this.deposit.accountType === "F";
    },
    amountRules() {
      const ruleArray = [this.required, this.minimumOne];

      if (this.deposit.depositType === "P") ruleArray.push(this.maxNinetyNine);
      if (this.deposit.depositType === "F") ruleArray.push(this.maxHundredThousand);

      return ruleArray;
    },
    depositeHeader() {
      let accountType;

      switch (this.deposit.accountType) {
        case "C":
          accountType = "Checking Account / ";
          break;
        case "S":
          accountType = "Savings Account / ";
          break;
        case "F":
          accountType = "Fuel Card / ";
          break;
        default:
          throw new Error(`Unknown accountType '${this.deposit.accountType}'`);
      }

      switch (this.deposit.depositType) {
        case "F":
          return `${accountType}Dollar Amount`;
        case "P":
          return `${accountType}Percentage Amount`;
        case "NET":
          return `${accountType}Net Amount`;
        default:
          throw new Error(`Unknown depositType '${this.deposit.depositType}'`);
      }
    },
  },
  methods: {
    ...mapMutations("payroll/directDeposit", [
      "setDepositBankName",
      "setDepositRoutingNumber",
      "setDepositAccountNumber",
      "setDepositAmount",
      "setDepositType",
    ]),
    formattingAmount() {
      const amount = this.amountToFormat.toString().replace(",", "");
      this.setDepositAmount({
        feId: this.deposit.feId,
        amount,
      });
    },
    required(val) {
      return validators.required(val) || "This field is required";
    },
    routingNumber(val) {
      return validators.routingNumber(val) || "Invalid routing number";
    },
    minimumOne(val) {
      if (typeof val === "string") {
        val = parseFloat(val.replace(/,/g, ""));
        return val >= 1 || "1 is the minimum amount";
      }
      return val >= 1 || "1 is the minimum amount";
    },
    maxNinetyNine(val) {
      if (typeof val === "string") {
        val = parseFloat(val.replace(/,/g, ""));
        return val <= 99 || "99 is the maximum amount";
      }
      return val <= 99 || "99 is the maximum amount";
    },
    maxHundredThousand(val) {
      if (typeof val === "string") {
        val = parseFloat(val.replace(/,/g, ""));
        return val <= 100000 || "100,000 is the maximum amount";
      }
      return val <= 100000 || "100,000 is the maximum amount";
    },
    confirmDelete() {
      this.isDeleting = false;
      this.$emit("delete");
    },
    validate() {
      this.$refs.form.validate();
      let errors = [];
      Object.keys(this.$refs).forEach((ref) => {
        const errorBucket = this.$refs[ref]?.errorBucket;
        if (errorBucket && errorBucket.length) errors.push(errorBucket);
      });
      errors = errors.flat();

      if (errors.length === 1 && errors[0] === "Invalid routing number") {
        this.valid = true;
      }
    },
    changeValidAmount(value) {
      this.setDepositAmount({
        feId: this.deposit.feId,
        amount: value,
      });
      if (this.amountValidation.test(value)) {
        // Input is valid, check the number of decimal places
        if (value.match(this.twoDecimalPlaces)) {
          // all good
          return;
        }
        if (value.match(this.noDecimalPlacesWithDecimal)) {
          // add two decimal places
          this.setDepositAmount({
            feId: this.deposit.feId,
            amount: `${value}00`,
          });
          return;
        }
        if (value.match(this.oneDecimalPlace)) {
          // add one decimal place
          this.setDepositAmount({
            feId: this.deposit.feId,
            amount: `${value}0`,
          });
          return;
        }
        // else there is no decimal places and no decimal
        this.setDepositAmount({
          feId: this.deposit.feId,
          amount: `${value}.00`,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input.custom-red .v-input--selection-controls__input div {
  color: #ef5350;
}
</style>
