import state from "./state";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import driverText from "./driverText";
import smokingPreference from "./smokingPreference";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    driverText,
    smokingPreference,
  },
};
