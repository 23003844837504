import dayjs from "dayjs";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
const isBetween = require("dayjs/plugin/isBetween");
const customParseFormat = require("dayjs/plugin/customParseFormat");
const duration = require("dayjs/plugin/duration");

/*
  Tech Debt
  Possibly move this to the settings table under a new key 'holidayDescriptions'
 */
const holidayDescriptions = {
  "4,5,1": "Memorial Day",
  "6,": "Independence Day",
  "8,1,1": "Labor Day",
  "10,4,4": "Thanksgiving Day",
  "11,": "Christmas Day",
  "0,": "New Year's Day",
};

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(duration);

const dateFromString = (dateString) => dayjs(dateString).toDate();

const dateFromMezzioDateJson = (mezzioDateJson) => dayjs(mezzioDateJson.date).tz(mezzioDateJson.timeZone).toDate();

const dateObject = (dateStrOrObj) => {
  if (dateStrOrObj instanceof Date) {
    return dateStrOrObj;
  }

  return dayjs(dateStrOrObj).toDate();
};

const dateString = (dateObj) => dayjs(dateObj).format("YYYY-MM-DD");

const dateFormalString = (dateStrOrObj) => dayjs(dateObject(dateStrOrObj)).format("ddd MM/DD/YYYY");

const dateTimeString = (dateStrOrObj) => dayjs(dateObject(dateStrOrObj)).format("YYYY-MM-DD HH:mm A");

const dateIsMonthsPast = (dateStr, months) => {
  const diff = dayjs().diff(dateStr, "month", true);

  return diff > months;
};

const dateIsMonthsFuture = (dateStr, months) => {
  const diff = dayjs(dateStr).diff(dayjs(), "month", true);

  return diff > months;
};

const dateNextMonth = (dateStr, maxMonths, interval) => {
  const maxMonth = dayjs().add(maxMonths, "month");
  const diff = maxMonth.diff(dayjs(dateStr), "month");

  return diff > interval ? interval : diff;
};

const datePrevMonth = (dateStr, maxMonths, interval) => {
  const maxMonth = dayjs().subtract(maxMonths, "month");
  const diff = dayjs(dateStr).diff(maxMonth, "month", true);

  return diff > interval ? interval : Math.ceil(diff);
};

const dateBeforeToday = (days) => dayjs().subtract(days, "day").toDate();

const dateAfterToday = (days) => dayjs().add(days, "day").toDate();

const dayOfWeek = (dateStrOrObj) => dayjs(dateStrOrObj).format("dddd");

const dateIsAfter = (dateStrOrObj, minDateStrOrObj) => dayjs(dateStrOrObj).isAfter(minDateStrOrObj, "day");

const dateIsBefore = (dateStrOrObj, maxDateStrOrObj) => dayjs(dateStrOrObj).isBefore(maxDateStrOrObj, "day");

const dateIsBetween = (
  dateStrOrObj,
  minDateStrOrObj,
  maxDateStrOrObj,
) => dayjs(dateStrOrObj).isBetween(
  minDateStrOrObj,
  maxDateStrOrObj,
  "day",
  "[]",
);

const daysInMonth = (year, month) => dayjs(`${year}-${month}`, "YYYY-M").daysInMonth();

const dateToString = (value) => dayjs(value ?? "").format("MM/DD/YYYY");

const weekOfMonth = (date) => Math.ceil(dayjs(date).date() / 7);

const dateHolidayName = (date) => {
  const dateObj = dayjs(date);
  const week = weekOfMonth(date);

  let name = holidayDescriptions[`${dateObj.month()},${week},${dateObj.day()}`];

  if (name === undefined) {
    name = holidayDescriptions[`${dateObj.month()},`] ?? null;
  }

  return name;
};

const dateToStringCustomFormat = (dateStrOrObj, format) => dayjs(dateObject(dateStrOrObj)).format(format);

const todaysDate = (format) => dayjs().format(format);

/*
  Helper functions for internal use
 */

export default {
  dateFromString,
  dateString,
  dateFormalString,
  dateFromMezzioDateJson,
  dateTimeString,
  dateBeforeToday,
  dateAfterToday,
  dayOfWeek,
  dateIsBefore,
  dateIsAfter,
  dateIsBetween,
  daysInMonth,
  dateToString,
  dateIsMonthsPast,
  dateIsMonthsFuture,
  dateNextMonth,
  datePrevMonth,
  dateHolidayName,
  dateToStringCustomFormat,
  todaysDate,
};
