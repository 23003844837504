export default () => ({
  isFetching: false,
  fetchError: null,
  isSubmitting: false,
  submitError: {},
  optedIn: false,
  carrierAddress: "",
  areaCode: "",
  prefix: "",
  lineNumber: "",
  serviceProviders: [
    {
      name: "Alltel: message.alltel.com",
      value: "message.alltel.com",
    },
    {
      name: "AT&T: txt.att.net",
      value: "txt.att.net",
    },
    {
      name: "Bluegrass: sms.bluecell.com",
      value: "sms.bluecell.com",
    },
    {
      name: "Boost Mobile: myboostmobile.com",
      value: "myboostmobile.com",
    },
    {
      name: "CellCom: cellcom.quiktxt.com",
      value: "cellcom.quiktxt.com",
    },
    {
      name: "Centennial Wireless: cwemail.com",
      value: "cwemail.com",
    },
    {
      name: "Cricket Wireless: mms.mycricket.com",
      value: "mms.mycricket.com",
    },
    {
      name: "Cellular South: csouth1.com",
      value: "csouth1.com",
    },
    {
      name: "Fido: fido.ca",
      value: "fido.ca",
    },
    {
      name: "Golden State Cellular: gscsms.com",
      value: "gscsms.com",
    },
    {
      name: "Helio: myhelio.com",
      value: "myhelio.com",
    },
    {
      name: "Net10: mmst5.tracfone.com",
      value: "mmst5.tracfone.com",
    },
    {
      name: "MetroPCS: mymetropcs.com",
      value: "mymetropcs.com",
    },
    {
      name: "Nextel: messaging.nextel.com",
      value: "messaging.nextel.com",
    },
    {
      name: "Ntelos: pcs.ntelos.com",
      value: "pcs.ntelos.com",
    },
    {
      name: "Qwest: qwestmp.com",
      value: "qwestmp.com",
    },
    {
      name: "Sprint: messaging.sprintpcs.com",
      value: "messaging.sprintpcs.com",
    },
    {
      name: "SunCom: tms.suncom.com",
      value: "tms.suncom.com",
    },
    {
      name: "TMobile: tmomail.net",
      value: "tmomail.net",
    },
    {
      name: "UniCel: utext.com",
      value: "utext.com",
    },
    {
      name: "US Cellular: email.uscc.net",
      value: "email.uscc.net",
    },
    {
      name: "Verizon: vtext.com",
      value: "vtext.com",
    },
    {
      name: "Viaero: viaerosms.com",
      value: "viaerosms.com",
    },
    {
      name: "Virgin Mobile: vmobl.com",
      value: "vmobl.com",
    },
  ],
});
