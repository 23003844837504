import Vue from "vue";
import store from "@/store";
import core from "./core";
import applicationConfig from "./applicationConfig";
import userInfo from "./userInfo";
import essUserInfo from "./essUserInfo";

const apis = {
  core,
  applicationConfig,
  userInfo,
  essUserInfo,
  setAuthToken(token) {
    Object.keys(Vue.$apis).forEach((key) => {
      if (Vue.$apis[key]?.api?.defaults) {
        Vue.$apis[key].api.defaults.headers.common.Authorization = token;
      }
    });
  },
  clearAuthToken() {
    Object.keys(Vue.$apis).forEach((key) => {
      if (Vue.$apis[key]?.api?.defaults) {
        delete Vue.$apis[key].api.defaults.headers.common.Authorization;
      }
    });
  },
  addApi(newAPI) {
    const token = store.getters["auth/authToken"];
    Vue.$apis[newAPI.name()] = newAPI;
    if (token !== "") {
      Vue.$apis[newAPI.name()].api.defaults.headers.common.Authorization = token;
    }
  },
};

const apiWrapper = {
  // eslint-disable-next-line no-shadow
  install(Vue) {
    Vue.$apis = apis;
    Vue.prototype.$apis = apis;
  },
};

Vue.use(apiWrapper);

export default apis;
