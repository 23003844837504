export default {
  isFetching(state) {
    return state.isFetching;
  },
  fetchError(state) {
    return state.fetchError;
  },
  isSubmitting(state) {
    return state.isSubmitting;
  },
  submitError(state) {
    return state.submitError;
  },
  smokingPreference(state) {
    return state.smokingPreference;
  },
  hasUnsavedChanges(state) {
    return state.smokingPreference !== state.originalValue;
  },
};
