export default {
  isFetching(state) {
    return state.isFetching;
  },
  fetchError(state) {
    return state.fetchError;
  },
  isSubmitting(state) {
    return state.isSubmitting;
  },
  submitError(state) {
    return state.submitError;
  },
  hireDate(state) {
    return state.hireDate;
  },
  isNewYork(state) {
    return state.isNewYork;
  },
  payCycle(state) {
    return state.payCycle;
  },
  payType(state) {
    return state.payType;
  },
  payRate(state) {
    return state.payRate;
  },
  rateSheet(state) {
    return state.rateSheet;
  },
};
