import newYorkAcknowledgePayRateAPI from "@/modules/payroll/api/newYorkAcknowledgePayRate";

export default {
  async fetchNAPRAccess({ commit }) {
    commit("setIsFetching", true);
    commit("setFetchError", null);

    try {
      const { data } = await newYorkAcknowledgePayRateAPI.fetchNAPRAccess();

      commit("setFormData", data.newYorkAcknowledgePayRate);
    } catch (error) {
      if (error.response) {
        commit("setFetchError", error.response.data);
      } else if (error.request) {
        commit("setFetchError", {
          title: "Bad Request",
          status: "",
          detail: "Could not complete this request",
        });
      } else {
        commit("setFetchError", "Unknown error");
      }
    } finally {
      commit("setIsFetching", false);
    }
  },

  async submitNAPRAcknowledgement({ commit }, payload) {
    commit("setIsSubmitting", true);
    commit("setSubmitError", {});

    try {
      await newYorkAcknowledgePayRateAPI.submitNAPRAcknowledgement(payload);
    } catch (error) {
      if (error.response) {
        commit("setSubmitError", error.response.data);
      } else if (error.request) {
        commit("setSubmitError", {
          title: "Bad Request",
          status: "",
          detail: "Could not complete this request",
        });
      } else {
        commit("setSubmitError", "Unknown error");
      }
    } finally {
      commit("setIsSubmitting", false);
    }
  },
};
