export default {
  isFetching(state) {
    return state.isFetching;
  },
  fetchError(state) {
    return state.fetchError;
  },
  isSubmitting(state) {
    return state.isSubmitting;
  },
  submitError(state) {
    return state.submitError;
  },
  optedIn(state) {
    return state.optedIn;
  },
  carrierAddress(state) {
    return state.carrierAddress;
  },
  areaCode(state) {
    return state.areaCode;
  },
  prefix(state) {
    return state.prefix;
  },
  lineNumber(state) {
    return state.lineNumber;
  },
  serviceProviders(state) {
    return state.serviceProviders;
  },
};
