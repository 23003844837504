export default {
  setIsFetching(state, data) {
    state.isFetching = data;
  },
  setFetchError(state, data) {
    state.fetchError = data;
  },
  setIsSubmitting(state, data) {
    state.isSubmitting = data;
  },
  setSubmitError(state, data) {
    state.submitError = data;
  },
  setSmokingPreference(state, data) {
    state.smokingPreference = data === "" ? "N" : data;
  },
  setOriginalValue(state, data) {
    state.originalValue = data;
  },
};
