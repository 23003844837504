<template>
  <pageManager :isLoading="isFetching" :error="''" :isEmpty="false">
    <v-container>
      <v-card elevation="2">
        <v-card-title class="headline white--text primary font-weight-light">
          {{ title }}
        </v-card-title>
        <v-spacer />
        <v-card-text class="form">
          <v-row>
            <v-spacer />
            <v-col cols="12" lg="8">
              <v-row>
                <v-spacer />
                <v-col cols="12" md="10">
                  <v-row class="current-user">
                    <v-col cols="12" sm="4">
                      <display-field :value="employeeName" label="Employee Name" />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <display-field :value="employeeNumber" label="Employee #" />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <display-field :value="employeeSSNLast4" label="Last 4 SSN" />
                    </v-col>
                  </v-row>
                  <v-card class="acknowledgementDescription">
                    <p>Mileage is paid based on the Rand McNally Household Goods Guide, unless otherwise specified.
                      Rand McNally Household Goods Miles do not equate to all miles actually driven.</p>
                    <p>Mileage pay covers time spent engaged in essential activities normally or typically associated
                      with a trip and the act of operating a vehicle in compliance with state and federal laws,
                      including, but not limited to, rest break time, the execution of pre- and post-trip vehicle
                      inspections, time spent when a customer loads or unloads the trailer, traffic delays, weather
                      delays, pulling through weigh stations, routine maintenance inspections, enroute vehicle
                      inspections, routine DOT safety inspections, truck washing, waiting for dispatch, drop and
                      hooking, chaining, fueling, all trip-related paperwork and documents (including
                      customer-required paperwork), and limited delay intervals that are normally or typically
                      associated with trips (i.e., show-up to scheduled depart time prior to a customer being ready
                      to load and unload freight, delays up to 2 hours at a customer site, etc). Please notify the
                      Payroll department immediately if you believe your pay does not properly compensate you for
                      all hours worked.</p>
                  </v-card>
                  <div class="signature-required" :class="{ invisible: isSigned }">
                    Electronic Signature Required:
                  </div>
                  <v-checkbox
                    class="electronic-signature"
                    v-model="isSigned"
                    label="By checking here, I am providing an electronic signature confirming I have read through
                  the information provided above. I understand the electronic signature is binding in the same manner as
                  a handwritten signature."
                  />
                  <v-checkbox
                    :disabled="emailNotificationNotAvailable"
                    v-model="requestEmailNotification"
                    :label="'Send me an email confirmation to: ' + notificationEmail"
                  />
                  <v-alert
                    v-show="emailNotificationNotAvailable"
                    outlined
                    dense
                    text
                    type="warning"
                  >
                    <v-row>
                      <v-col cols="7">
                        You do not have a notification email on file. You can continue without an email confirmation, or
                        go to My Information to set a personal email address.
                      </v-col>
                      <v-col cols="5" class="d-flex align-center justify-center">
                        <v-btn color="warning" @click="openMyInfo">Edit My Information</v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
                <v-spacer />
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div class="text-center">
                    <v-btn @click="submitAccepted" :disabled="disableAcknowledgementButtons" color="success">
                      Accept
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="text-center">
                    <v-btn @click="submitRefused" :disabled="disableAcknowledgementButtons" color="error">
                      Refuse to Sign
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-spacer />
          </v-row>

        </v-card-text>
      </v-card>
      <submitDialog :propIsSubmitting="isSubmitting" :propSubmitError="submitError" @clearErrors="clearErrors()" />
      <successDialog
        :message="successDialog.message"
        :messageHTML="successDialog.messageHTML"
        :show="successDialog.show"
        @close="cleanUpAndExit"
      />
    </v-container>
  </pageManager>
</template>

<script>
import infoDialog from "@/components/info-dialog.vue";
import modulePageMixin from "@/utils/mixins";
import { Component, Mixins } from "vue-property-decorator";
import PageManager from "@/components/pageManager.vue";
import { namespace } from "vuex-class";
import submitDialog from "@/components/submitDialog.vue";
import _ from "lodash";
import successDialog from "@/components/successDialog.vue";
import DisplayField from "@/components/displayField.vue";

const milesBasedPayAcknowledgeStore = namespace("payroll/milesBasedPayAcknowledge");
const configStore = namespace("appConfig");
const authStore = namespace("auth");

@Component({
  name: "MilesBasedPayAcknowledge",
  components: {
    successDialog,
    submitDialog,
    PageManager,
    infoDialog,
    DisplayField,
  },
  provide() {
    return { currentYear: this.currentYear };
  },
})

export default class MilesBasedPayAcknowledge extends Mixins(modulePageMixin) {
  @milesBasedPayAcknowledgeStore.Getter("isSubmitting") isSubmitting;

  @milesBasedPayAcknowledgeStore.Getter("emailSent") emailSent;

  @milesBasedPayAcknowledgeStore.Getter("sendEmailNotification") sendEmailNotification;

  @milesBasedPayAcknowledgeStore.Mutation("setSubmitError") setSubmitError;

  @milesBasedPayAcknowledgeStore.Getter("submitError") submitError;

  @milesBasedPayAcknowledgeStore.Mutation("setSendEmailNotification") setSendEmailNotification;

  @milesBasedPayAcknowledgeStore.Action("submitAcknowledgement") submitAcknowledgement;

  @configStore.Getter("getSwiftEmailEditUrl") getSwiftEmailEditUrl;

  @authStore.Action("reloadUserInfo") reloadUserInfo;

  isFetching = false;

  title = "Miles Based Pay Acknowledgement";

  isSigned = false;

  successDialog = {
    message: "Your miles base pay acknowledgement has been submitted for processing.",
    messageHTML: "",
    show: false,
  };

  get employeeName() {
    return this.currentUserFullName;
  }

  get employeeNumber() {
    return this.currentUserEmployeeId;
  }

  get employeeSSNLast4() {
    return this.currentUserSSNLast4;
  }

  get disableAcknowledgementButtons() {
    return !this.isSigned;
  }

  get requestEmailNotification() {
    return (this.emailNotificationNotAvailable)
      ? false
      : this.sendEmailNotification;
  }

  set requestEmailNotification(value) {
    this.setSendEmailNotification(value);
  }

  get emailNotificationNotAvailable() {
    return !this.hasNotificationEmail;
  }

  openMyInfo() {
    this.$router.push("/personal-info/my-information");
  }

  clearErrors() {
    this.setSubmitError({});
  }

  submitAccepted() {
    this.submit("ACCEPTED");
  }

  submitRefused() {
    this.submit("REFUSED");
  }

  async submit(status) {
    await this.submitAcknowledgement(
      {
        signed: this.isSigned,
        status,
        sendEmailNotification: this.sendEmailNotification,
      },
    );
    this.showSuccessDialog("submit");
  }

  showSuccessDialog() {
    if (this.emailSent !== "") {
      this.successDialog.messageHTML = `<p>
You will receive an email confirmation at your notification email address: ${
  this.emailSent
}</p>`;
    }

    this.successDialog.show = _.isEmpty(this.submitError);
  }

  cleanUpAndExit() {
    this.isSigned = false;
    this.successDialog.show = false;
    this.successDialog.messageHTML = "";
    this.$router.push("/");
  }

  async mounted() {
    this.isFetching = true;

    await this.reloadUserInfo();

    this.isFetching = false;
  }
}
</script>

<style lang="scss" scoped>
div.signature-required {
  margin-bottom: 0;
  margin-top: 16px;
}
.electronic-signature.v-input--selection-controls {
  margin-top: 0;
}
.acknowledgementDescription {
  font-size: 20px;
  border-radius: 30px 0 30px 0;
  background-color: lightgray;
  padding: 15px;
}
.checkbox-label {
  line-height: 1.2;
  font-size: 1.35em;
}
.signature-required {
  text-align: left;
  font-size: 20px;
  color: #ff0000;
  margin-bottom: 16px;
}
.invisible {
  visibility: hidden;
}
</style>
