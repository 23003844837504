<template>
  <pageManager :isLoading="isFetching" :error="fetchError" :isEmpty="false">
    <v-container>
      <v-card elevation="2">
        <v-card-title class="headline white--text primary font-weight-light">
          Update Smoker/Non-Smoker Status
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-spacer />
            <v-col cols="12" md="10" lg="8" xl="6" class="mt-3 text-center">
              <p>
                Smoker/Non-Smoker status is used to assist in assigning compatible trainees and mentors.
                It is not used for any benefits or health related reasons.
              </p>
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols="12" md="10" lg="8" xl="6" class="text-center">
              <p>
                Your current Smoker/Non-Smoker status is reflected in the drop down box.
                Please change as necessary and click on 'Submit Change'.
              </p>
            </v-col>
            <v-spacer />
          </v-row>
          <v-row class="align-center">
            <v-col cols="12" sm="6" class="label">
              <p>Select Smoker/Non-Smoker Status:</p>
            </v-col>
            <v-col cols="12" sm="6" md="5" lg="4" xl="3">
              <v-select
                label="Status"
                outlined
                :items="statuses"
                item-text="text"
                item-value="value"
                hide-details
                :value="getSmokingPreference"
                @input="setSmokingPreference"
              />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols="12" sm="6" md="5" lg="4" xl="3">
              <v-btn
                class="action-button"
                color="primary"
                @click="submitChange()"
                :disabled="!hasUnsavedChanges">
                Submit Change
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="5" lg="4" xl="3">
              <v-btn class="action-button" color="primary" @click="returnToDashboard()">Back to Dashboard</v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <submitDialog :propIsSubmitting="isSubmitting" :propSubmitError="submitError" @clearErrors="clearErrors()" />
    <successDialog :message="successDialog.message" :show="successDialog.show" @close="closeSuccessDialog()" />
    <unsavedChangesDialog
      :show="unSavedChanges.showDialog"
      :title="'You have unsaved changes.'"
      :cancelLabel="'Stay on this page'"
      :continueLabel="'Discard Changes'"
      @cancelNavigation="cancelNavigation"
      @continueNavigation="continueNavigation"
    />
  </pageManager>
</template>

<script>
import Component from "vue-class-component";
import pageManager from "@/components/pageManager.vue";
import { namespace } from "vuex-class";
import Vue from "vue";
import submitDialog from "@/components/submitDialog.vue";
import successDialog from "@/components/successDialog.vue";
import unsavedChangesDialog from "@/components/unsaved-changes-dialog.vue";
import _ from "lodash";

const smokingPreferenceStore = namespace("drivers/smokingPreference");

Component.registerHooks([
  "beforeRouteLeave",
]);

@Component({
  name: "SmokingPreference",
  components: {
    successDialog,
    submitDialog,
    pageManager,
    unsavedChangesDialog,
  },
})
export default class SmokingPreference extends Vue {
  @smokingPreferenceStore.Getter("isFetching") isFetching;

  @smokingPreferenceStore.Getter("fetchError") fetchError;

  @smokingPreferenceStore.Getter("isSubmitting") isSubmitting;

  @smokingPreferenceStore.Getter("submitError") submitError;

  @smokingPreferenceStore.Getter("smokingPreference") getSmokingPreference;

  @smokingPreferenceStore.Getter("hasUnsavedChanges") hasUnsavedChanges;

  @smokingPreferenceStore.Mutation("setSubmitError") setSubmitError;

  @smokingPreferenceStore.Mutation("setSmokingPreference") setSmokingPreference;

  @smokingPreferenceStore.Action("fetchSmokingPreference") fetchSmokingPreference;

  @smokingPreferenceStore.Action("updateSmokingPreference") updateSmokingPreference;

  statuses = [
    {
      text: "Smoker",
      value: "Y",
    },
    {
      text: "Non-Smoker",
      value: "N",
    },
  ];

  successDialog = {
    show: false,
    message: "Successfully Updated Smoking Preference!",
  };

  navigateDialog = {
    show: false,
    navigateTo: null,
  };

  unSavedChanges = {
    showDialog: false,
    navigateTo: null,
  };

  async submitChange() {
    const payload = { smokingPreference: this.getSmokingPreference };

    await this.updateSmokingPreference(payload);

    this.showSuccessDialog();
  }

  returnToDashboard() {
    this.$router.push("/");
  }

  clearErrors() {
    this.setSubmitError({});
  }

  showSuccessDialog() {
    this.successDialog.show = _.isEmpty(this.submitError);
  }

  closeSuccessDialog() {
    this.successDialog.show = false;
    this.returnToDashboard();
  }

  continueNavigation() {
    this.unSavedChanges.showDialog = false;
    this.$router.push(this.unSavedChanges.navigateAnywayRoute);
  }

  cancelNavigation() {
    this.unSavedChanges.showDialog = false;
    this.unSavedChanges.navigateAnywayRoute = null;
  }

  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      if (this.unSavedChanges.navigateAnywayRoute) {
        next();
      } else {
        this.unSavedChanges.navigateAnywayRoute = to;
        this.unSavedChanges.showDialog = true;
      }
    } else {
      next();
    }
  }

  async mounted() {
    await this.fetchSmokingPreference();
  }
}
</script>

<style scoped>
p {
    margin-bottom: 0;
    font-size: 20px;
}

.label {
    text-align: center;
}

@media (min-width: 600px) {
    .label {
        text-align: right;
    }
}

.action-button {
    text-align: center;
    width: 100%;
}
</style>
