<template>
  <pageManager :isLoading="isFetching" :error="fetchError" :isEmpty="false">
    <v-container>
      <v-card elevation="2">
        <v-card-title class="headline white--text primary font-weight-light">
          Swift Charities
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-spacer />
            <v-col cols="12" md="10" lg="8" xl="6">
              <div class="employee-name">
                {{ employeeName }}
              </div>
              <div class="text-center">
                <p>
                  <strong>
                    Swift Charities is a non-profit charity organization. Your contributions may be tax deductible.<br>
                    For further information, please consult with your tax advisor.
                  </strong>
                </p>
                <p>
                  Your current Swift Charities contributions are shown below. You may make any changes as desired.<br>
                  Please make all contributions in $1 increments (no decimals). To remove an existing contribution,
                  change the dollar amount to zero.
                </p>
              </div>
              <v-form ref="charitiesForm" v-model="isFormValid" class="selections">
                <div class="selections-title">
                  <strong>Swift Charities Selections:</strong>
                </div>
                <v-row>
                  <v-col cols="6" class="text-right">
                    <strong>Fund Description</strong>
                  </v-col>
                  <v-col cols="6" class="text-left">
                    <strong>Contribution Amount (per pay period)</strong>
                  </v-col>
                </v-row>
                <v-row class="mt-0 align-center">
                  <v-col cols="6" class="text-right">
                    General Fund<br>(allocated as needed to fund all programs)
                  </v-col>
                  <v-col cols="6" md="4" class="text-left">
                    <v-text-field
                      type="number"
                      v-model="generalFundAmount"
                      prefix="$"
                      class="amount-selector"
                      :rules="[moneyWholeDollars]"
                    />
                  </v-col>
                  <v-spacer />
                </v-row>
              </v-form>
              <div class="text-center">
                <p>
                  Accept and record these selections by clicking on the ACCEPT button, or click on DISCARD to abandon.
                  <br>New contributions and changes will be effective with the next payroll processing cycle.<br>
                  Any contributions cancelled will be discontinued immediately.
                </p>
              </div>
              <div>
                <v-checkbox
                  :disabled="!hasNotificationEmail"
                  v-model="sendEmailNotification"
                  :label="'Send me an email confirmation to: ' + notificationEmail"
                />
                <v-alert
                  v-show="!hasNotificationEmail"
                  outlined
                  dense
                  text
                  type="warning"
                >
                  <v-row>
                    <v-col cols="12" md="7">
                      You do not have a notification email on file. You can continue without an email confirmation, or
                      go to My Information to set a personal email address.
                    </v-col>
                    <v-col cols="12" md="5" class="d-flex align-center justify-center">
                      <v-btn color="warning" @click="openMyInfo()">Edit My Information</v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </div>
              <v-row class="mb-4">
                <v-spacer />
                <v-col cols="6" sm="4" xl="3" class="text-center">
                  <v-btn
                    class="action-button"
                    color="primary"
                    :disabled="!isFormValid"
                    @click="submitSelections()"
                  >
                    Accept
                  </v-btn>
                </v-col>
                <v-col cols="6" sm="4" xl="3" class="text-center">
                  <v-btn class="action-button" color="primary" @click="returnToDashboard()">Discard</v-btn>
                </v-col>
                <v-spacer />
              </v-row>
              <div class="disclaimer">
                Indicate your acceptance of these selections by clicking the ACCEPT button.
                Please be advised that by clicking ACCEPT you are providing an electronic
                signature that is binding in the same manner as a handwritten signature.
                <br><br>
                In addition, this electronic signature authorizes Swift Transportation, Inc to
                withhold payroll deductions based on the selections recorded here.
              </div>
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-text>
      </v-card>
      <submitDialog :propIsSubmitting="isSubmitting" :propSubmitError="submitError" @clearErrors="clearErrors()" />
      <successDialog :message="successDialog.message" :show="successDialog.show" @close="closeSuccessDialog()" />
    </v-container>
  </pageManager>
</template>

<script>
/* eslint-disable class-methods-use-this */
import Component from "vue-class-component";
import pageManager from "@/components/pageManager.vue";
import modulePageMixin from "@/utils/mixins";
import { Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import submitDialog from "@/components/submitDialog.vue";
import successDialog from "@/components/successDialog.vue";
import validators from "@/utils/validators";
import _ from "lodash";

const charitiesStore = namespace("payroll/charities");
const authStore = namespace("auth");

@Component({
  name: "CharitiesForm",
  components: { successDialog, submitDialog, pageManager },
})
export default class CharitiesForm extends Mixins(modulePageMixin) {
  @charitiesStore.Action("fetchCharityData") fetchCharityData;

  @charitiesStore.Action("submitCharitySelections") submitCharitySelections;

  @charitiesStore.Getter("fetchError") fetchError;

  @charitiesStore.Getter("isSubmitting") isSubmitting;

  @charitiesStore.Getter("submitError") submitError;

  @charitiesStore.Getter("amounts") amounts;

  @charitiesStore.Mutation("setSubmitError") setSubmitError;

  @authStore.Action("reloadUserInfo") reloadUserInfo;

  generalFundAmount = 0;

  sendEmailNotification = false;

  isFetching = false;

  successDialog = {
    show: false,
    message: "Successfully Updated Swift Charities Selections!",
  };

  isFormValid = false;

  get employeeName() {
    return this.currentUserFullName;
  }

  openMyInfo() {
    this.$router.push("/personal-info/my-information");
  }

  async submitSelections() {
    const payload = {
      amounts: `|||${this.generalFundAmount}|||||||||`,
      sendEmailNotification: this.sendEmailNotification,
    };

    await this.submitCharitySelections(payload);

    this.showSuccessDialog();
  }

  clearErrors() {
    this.setSubmitError({});
  }

  showSuccessDialog() {
    this.successDialog.show = _.isEmpty(this.submitError);
  }

  closeSuccessDialog() {
    this.successDialog.show = false;
    this.$router.push("/");
  }

  returnToDashboard() {
    this.$router.push("/");
  }

  validate() {
    return this.$refs.charitiesForm.validate();
  }

  moneyWholeDollars(value) {
    return validators.moneyWholeDollars(value) || "Value must be whole dollar amount";
  }

  async mounted() {
    this.isFetching = true;

    await this.reloadUserInfo();
    await this.fetchCharityData();

    this.isFetching = false;

    let amt4 = this.amounts.amt4 ?? "";

    if (amt4 === "" || amt4 === ".00") {
      amt4 = "0";
    }

    this.generalFundAmount = parseInt(amt4, 10);
    this.sendEmailNotification = this.hasNotificationEmail;

    this.validate();
  }
}
</script>

<style scoped>
.employee-name {
    text-align: center;
    font-size: 24px;
    margin: 16px 0;
}

.selections {
    border: double;
    margin-bottom: 16px;
}

.selections-title {
    text-align: center;
    font-size: 24px;
    margin: 8px 0 16px 0;
}

.amount-selector {
    width: 60%;
}

.action-button {
    width: 100%;
}

.disclaimer {
    border: double;
    padding: 4px;
}
</style>
