import { mapState, mapGetters } from "vuex";
import {
  createDynamicAsset,
  loadDynamicComponent,
  loadDynamicFieldComponent,
} from "@/utils/dynamicAssets";

export default {
  page() {
    return this.getModuleByName(this.$route.name)?.metaInfo ?? {};
  },
  computed: {
    ...mapState("appConfig", ["modules"]),
    ...mapState("personalInfo/myInformation", ["personalInfo"]),
    ...mapGetters("appConfig", ["getModuleByName"]),
    ...mapGetters("auth", [
      "userId",
      "userFullName",
      "companyEmailAddress",
      "personalEmailAddress",
      "isDriver",
      "employeeId",
      "employeeSsn",
    ]),
    moduleName() {
      return this.getModuleByName(this.$route.name)?.moduleName ?? "";
    },
    appTitle() {
      return this.$appInfo.title;
    },
    appSubTitle() {
      return this.$appInfo.subTitle ?? "";
    },
    appVersion() {
      return this.$appInfo.version ?? "";
    },
    appCompany() {
      return this.$appInfo.company ?? "";
    },
    appCompanyTitle() {
      return this.$company.companyTitle ?? "";
    },
    isSwift() {
      return this.$appInfo.company.toLowerCase() === "swift";
    },
    isKnight() {
      return this.$appInfo.company.toLowerCase() === "knight";
    },
    currentUserId() {
      return this.userId?.trim();
    },
    currentUserFullName() {
      return this.userFullName;
    },
    currentUserEmployeeId() {
      return this.employeeId?.trim();
    },
    currentUserSSNLast4() {
      const ssn = this.employeeSsn?.trim() ?? "";

      return ssn.substring(ssn.length - 4);
    },
    hasNotificationEmail() {
      return this.getNotificationEmail() !== "";
    },
    notificationEmail() {
      return this.getNotificationEmail();
    },
  },
  methods: {
    getAssetPath(assetFile) {
      return createDynamicAsset(assetFile);
    },
    loadComponent(component, module) {
      return loadDynamicComponent(component, module);
    },
    loadField(component, module) {
      return loadDynamicFieldComponent(component, module);
    },
    updateUrlQuery(query) {
      this.$router
        .replace({ path: this.$route.path, query })
        .catch(() => {});
    },
    getUrlSegment(index) {
      const parts = this.$route.path.split("/");
      return parts[index - 1] ?? "";
    },
    getNotificationEmail() {
      const personalEmail = this.isKnight
        ? this.personalInfo?.personalEmailAddress.trim()
        : this.personalEmailAddress.trim();

      if (this.isDriver) {
        return personalEmail;
      }

      const companyEmail = this.isKnight
        ? this.personalInfo?.companyEmailAddress?.trim()
        : this.companyEmailAddress.trim();

      // office employees
      return personalEmail === "" ? companyEmail : personalEmail;
    },
  },
};
