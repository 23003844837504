import Vue from "vue";
import driverTextAPI from "./driverText";
import smokingPreferenceAPI from "./smokingPreference";

export default {
  getApi() {
    return {
      driverTextAPI,
      smokingPreferenceAPI,
    };
  },
  registerApi() {
    Vue.$apis.addApi(driverTextAPI);
    Vue.$apis.addApi(smokingPreferenceAPI);
  },
};
