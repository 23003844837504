import Vue from "vue";
import { getApiError, makeError } from "@/utils/errorUtils";
import { merge } from "lodash";

export default {
  async login({ commit, dispatch }, { username, password }) {
    commit("setError", null);
    commit("setIsLoggingIn", true);

    try {
      const resp = await Vue.$apis.core.login(username, password);

      const jwt = resp?.data?.TokenId || "";

      await dispatch("authenticateWithJwt", { jwt });

      commit("setIsLoggingIn", false);
    } catch (error) {
      commit("setIsLoggingIn", false);
      commit("setError", getApiError(error));
    }
  },
  async loginSSO({ commit, dispatch }, { ssoToken }) {
    commit("setError", null);
    commit("setIsLoggingIn", true);

    try {
      const resp = await Vue.$apis.core.submitSsoToken(ssoToken);

      const jwt = resp?.data?.TokenId || "";

      await dispatch("authenticateWithJwt", { jwt });

      commit("setSsoLogin", true);
      commit("setIsLoggingIn", false);
    } catch (error) {
      commit("setIsLoggingIn", false);

      const company = Vue.$appInfo.company.toLowerCase();
      const companyCapitalized = company.charAt(0).toUpperCase() + company.slice(1);

      commit(
        "setError",
        makeError(
          "An SSO error occurred",
          `Could not complete SSO login. Please log in with your ${companyCapitalized} credentials`,
        ),
      );
    }
  },
  async authenticateWithJwt({ commit, dispatch, state }, { jwt }) {
    commit("setJWT", jwt);

    if (!state.token || !state.userId) {
      commit(
        "setError",
        makeError(
          "Authentication Error",
          "There was an error creating authentication",
        ),
      );
    }

    Vue.$apis.setAuthToken(state.token, jwt);

    switch (Vue.$appInfo.company.toLowerCase()) {
      case "knight":
        await dispatch("getUserInfo", state.userId).then(() => {
          if (!state.fetchUserInfoError) {
            commit("setIsLoggedIn", true);
          }
        });
        break;
      case "swift":
        await dispatch("fetchUserInfo", state.userId).then(() => {
          if (!state.fetchUserInfoError) {
            commit("setIsLoggedIn", true);
          }
        });
        break;
      default:
        throw new Error(`unknown company '${Vue.$appInfo.company.toLowerCase()}'`);
    }
  },
  async logout({ state, commit }) {
    try {
      commit("setIsLoggingOut", true);
      await Vue.$apis.core.logout(state.token);
      Vue.$apis.clearAuthToken();

      commit("clearAuthentication");
    } catch (error) {
      commit("setIsLoggingOut", false);
      console.warn("error: ", getApiError(error));
    }
  },
  /**
   * This will call the Knight /infinium/employee endpoint
   */
  async getUserInfo({ commit }, username) {
    commit("setError", null);
    commit("setFetchUserInfoError", null);

    try {
      const resp = await Vue.$apis.core.getUserInfo(username);
      commit("setUserInfo", resp.data);
    } catch (error) {
      commit("setError", getApiError(error));
      commit("setFetchUserInfoError", getApiError(error));
    }
  },
  /**
   * This will call the Swift /userInfo endpoint
   */
  async fetchUserInfo({ commit }, username) {
    commit("setError", null);
    commit("setFetchUserInfoError", null);

    try {
      const employeeData = await Vue.$apis.core.getUserInfo(username);
      const { data } = await Vue.$apis.userInfo.fetchUserInfo(username);
      const essData = await Vue.$apis.essUserInfo.fetchESSUserInfo(username);
      const { isNonExemptCaliforniaEmployee, isNewYorkEmployee } = essData.data.userInfo;

      commit(
        "setUserInfo",
        merge(
          employeeData.data,
          data._embedded.userInfo,
          {
            permissions: {
              canSubmitCAMealBreakWaiverForm: isNonExemptCaliforniaEmployee,
              canSubmitNAPRForm: isNewYorkEmployee,
            },
          },
        ),
      );
    } catch (error) {
      commit("setError", getApiError(error));
      commit("setFetchUserInfoError", getApiError(error));
    }
  },
  async reloadUserInfo({ state, dispatch }) {
    await dispatch("fetchUserInfo", state.userId);
  },
  async requestMultiFactorVerificationCode({ commit }, requestParams) {
    commit("setMultiFactorApiResponse", {});
    commit("setError", null);
    commit("setMultiFactorVerificationError", null);
    commit("setIsLoadingMultiFactor", true);

    try {
      const { data } = await Vue.$apis.core.requestMultiFactorVerificationCode(requestParams);

      commit("setMultiFactorApiResponse", data);
      commit("setIsLoadingMultiFactor", false);
    } catch (error) {
      commit("setIsLoadingMultiFactor", false);
      commit("setMultiFactorVerificationError", getApiError(error));
      commit("setError", getApiError(error));
    }
  },
  async submitMultiFactorVerificationCode({ commit }, code) {
    commit("setIsMultiFactorValidated", false);
    commit("setMultiFactorVerificationError", null);
    commit("setIsLoadingMultiFactor", true);

    try {
      await Vue.$apis.core.submitMultiFactorVerificationCode(code);

      commit("setIsLoadingMultiFactor", false);
      commit("setIsMultiFactorValidated", true);
    } catch (error) {
      commit("setIsLoadingMultiFactor", false);
      commit("setMultiFactorVerificationError", getApiError(error));
    }
  },
};
