export default {
  isSubmitting(state) {
    return state.isSubmitting;
  },
  submitError(state) {
    return state.submitError;
  },
  sendEmailNotification(state) {
    return state.sendEmailNotification;
  },
  emailSent(state) {
    return state.emailSent;
  },
};
