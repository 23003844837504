<template>
  <div>
    <driver-text-disclaimer v-if="!hasAcceptedDisclaimer" @accept="acceptDisclaimer()" />
    <driver-text-form v-else />
  </div>
</template>

<script>
import Component from "vue-class-component";
import DriverTextDisclaimer from "@/modules/drivers/driverText/driver-text-disclaimer.vue";
import DriverTextForm from "@/modules/drivers/driverText/driver-text-form.vue";

@Component({
  name: "DriverText",
  components: {
    DriverTextForm,
    DriverTextDisclaimer,
  },
})
export default class DriverText {
  hasAcceptedDisclaimer = false;

  acceptDisclaimer() {
    this.hasAcceptedDisclaimer = true;
  }
}
</script>
