<template>
  <v-dialog v-model="show" scrollable :max-width="maxWidth" persistent>
    <v-card>
      <v-card-title>
        <v-icon class="mr-2">mdi-check-circle-outline</v-icon>{{ title }}
      </v-card-title>
      <v-card-text>
        <p>{{ message }}</p>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="openMyInfo"> Edit Personal Information </v-btn>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="closeDialog"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Component, Mixins, Prop } from "vue-property-decorator";
import modulePageMixin from "@/utils/mixins";

@Component({
  name: "CheckEmailPreference",
})
export default class CheckEmailPreference extends Mixins(modulePageMixin) {
  @Prop({ type: String, required: false, default: "Email Address Check" }) title;

  @Prop({ type: String, default: "550" }) maxWidth;

  @Prop({ default: false }) show;

  openMyInfo() {
    this.$router.push("/personal-info/my-information");
  }

  closeDialog() {
    this.$emit("closeDialog");
    this.$router.push("/");
  }

  get message() {
    let msg = "You do not have a personal email address on file. Please click the Edit Personal Information button";

    if (this.isKnight) {
      msg += " (which will redirect you to the My Information page)";
    } else if (this.isSwift) {
      msg
        += " (which will redirect you to the Employee Self-Service site), "
        + "go to the \"Update your personal email address\" page";
    }

    msg += ", save a personal email address, and try again.";

    return msg;
  }
}
</script>

<style scoped></style>
