export default {
  setIsFetching(state, data) {
    state.isFetching = data;
  },
  setFetchError(state, data) {
    state.fetchError = data;
  },
  setIsSubmitting(state, data) {
    state.isSubmitting = data;
  },
  setSubmitError(state, data) {
    state.submitError = data;
  },
  setAmounts(state, data) {
    state.amounts = data;
  },
};
