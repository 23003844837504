<template>
  <pageManager :isLoading="isFetching" :error="fetchError" :isEmpty="false">
    <v-container>
      <v-card elevation="2">
        <v-card-title class="headline white--text primary font-weight-light">
          Driver Text Opt In/Out
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div class="current-status" :style="{ color: statusColor }">
                You are currently opted {{ statusMessage }} pre-plan text messaging.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols="12" md="10" lg="8" xl="6">
              <div class="instructions mb-6">
                Enter/change your text phone information below.
              </div>
              <v-form ref="driverTextForm" :v-model="isFormValid">
                <v-row>
                  <v-spacer />
                  <v-col cols="12" md="6">
                    <v-select
                      label="Service Provider"
                      outlined
                      :items="serviceProviders"
                      item-text="name"
                      item-value="value"
                      v-model="serviceProvider"
                      :rules="[required]"
                      @change="validate()"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" md="2">
                    <v-text-field
                      label="Area Code"
                      outlined
                      v-model.trim="phoneNumber.areaCode"
                      :rules="[numberValue, length3]"
                      @input="validate()"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" md="2">
                    <v-text-field
                      label="Prefix"
                      outlined
                      v-model.trim="phoneNumber.prefix"
                      :rules="[numberValue, length3]"
                      @input="validate()"
                    />
                  </v-col>
                  <v-col cols="12" sm="4" md="2">
                    <v-text-field
                      label="Line Number"
                      outlined
                      v-model.trim="phoneNumber.lineNumber"
                      :rules="[numberValue, length4]"
                      @input="validate()"
                    />
                  </v-col>
                  <v-spacer />
                </v-row>
              </v-form>
              <v-row>
                <v-col cols="12">
                  <div class="instructions">
                    <p class="blue-text">!! IMPORTANT !! SPRINT CUSTOMERS READ THIS!</p>
                    <p class="blue-text">
                      Although Sprint and Nextel have merged, they still maintain separate networks.
                      If you have a Nextel phone you MUST select Nextel as your Service Provider. If you
                      are unsure whether your phone is a Nextel, look for the letter 'I' as the first letter of
                      the model number or contact your Service Provider.
                    </p>
                    <p class="red-text">
                      If you are unsure of your Service Provider consult your cell phone manual or billing
                      statement.
                    </p>
                    <p class="red-text">
                      If you do not find your Service Provider in the above list, you may send an email to
                      txtalert@swifttrans.com. Please include your driver code, cell phone number, and
                      the name of your cell phone Service Provider.
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer />
                <v-col cols="12" sm="4" class="text-center" v-if="optedIn">
                  <v-btn
                    class="action-button"
                    color="primary"
                    @click="submitTextInfo('I')"
                    :disabled="!isFormValid"
                  >Update Text Info</v-btn>
                </v-col>
                <v-col cols="12" sm="4" class="text-center" v-if="optedIn">
                  <v-btn class="action-button" color="primary" @click="submitTextInfo('O')">Opt Me Out</v-btn>
                </v-col>
                <v-col cols="12" sm="4" class="text-center" v-if="!optedIn">
                  <v-btn
                    class="action-button"
                    color="primary"
                    @click="submitTextInfo('I')"
                    :disabled="!isFormValid"
                  >Opt Me In</v-btn>
                </v-col>
                <v-col cols="12" sm="4" class="text-center">
                  <v-btn class="action-button" color="primary" @click="returnToDashboard()">Discard</v-btn>
                </v-col>
                <v-spacer />
              </v-row>
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-text>
      </v-card>
      <submitDialog :propIsSubmitting="isSubmitting" :propSubmitError="submitError" @clearErrors="clearErrors()" />
      <successDialog
        :message="''"
        :messageHTML="successDialog.message"
        :show="successDialog.show"
        @close="closeSuccessDialog()"
      />
    </v-container>
  </pageManager>
</template>
<script>
/* eslint-disable class-methods-use-this */
import Component from "vue-class-component";
import Vue from "vue";
import pageManager from "@/components/pageManager.vue";
import { namespace } from "vuex-class";
import successDialog from "@/components/successDialog.vue";
import submitDialog from "@/components/submitDialog.vue";
import _ from "lodash";

const driverTextStore = namespace("drivers/driverText");

@Component({
  name: "DriverTextForm",
  components: { submitDialog, successDialog, pageManager },
})
export default class DriverTextForm extends Vue {
  @driverTextStore.Getter("isFetching") isFetching;

  @driverTextStore.Getter("fetchError") fetchError;

  @driverTextStore.Getter("isSubmitting") isSubmitting;

  @driverTextStore.Getter("submitError") submitError;

  @driverTextStore.Getter("optedIn") optedIn;

  @driverTextStore.Getter("carrierAddress") carrierAddress;

  @driverTextStore.Getter("areaCode") areaCode;

  @driverTextStore.Getter("prefix") prefix;

  @driverTextStore.Getter("lineNumber") lineNumber;

  @driverTextStore.Getter("serviceProviders") serviceProviders;

  @driverTextStore.Mutation("setSubmitError") setSubmitError;

  @driverTextStore.Action("fetchDriverTextData") fetchDriverTextData;

  @driverTextStore.Action("submitDriverTextSelections") submitDriverTextSelections;

  isFormValid = false;

  serviceProvider = "";

  phoneNumber = {
    areaCode: "",
    prefix: "",
    lineNumber: "",
  };

  successDialog = {
    show: false,
    message: "",
  };

  get statusMessage() {
    return this.optedIn ? "IN to" : "OUT of";
  }

  get statusColor() {
    return this.optedIn ? "#006400" : "#ff0000";
  }

  clearErrors() {
    this.setSubmitError({});
  }

  showSuccessDialog(message) {
    this.successDialog.show = _.isEmpty(this.submitError);
    this.successDialog.message = message ?? "";
  }

  closeSuccessDialog() {
    this.successDialog.show = false;
    this.successDialog.message = "";
    this.$router.push("/");
  }

  returnToDashboard() {
    this.$router.push("/");
  }

  required(value) {
    return (value !== null && value !== "") || "Select a Service Provider";
  }

  numberValue(value) {
    const num = parseInt(value, 10);

    return !Number.isNaN(num) || "Value must be a number";
  }

  length3(value) {
    return value.length === 3 || "Value must be 3 digits";
  }

  length4(value) {
    return value.length === 4 || "Value must be 4 digits";
  }

  validate() {
    this.isFormValid = this.$refs.driverTextForm.validate();
  }

  async submitTextInfo(status) {
    const payload = {
      status,
      carrierAddr: this.serviceProvider,
      areaCode: this.phoneNumber.areaCode,
      prefix: this.phoneNumber.prefix,
      lineNumber: this.phoneNumber.lineNumber,
    };

    await this.submitDriverTextSelections(payload);

    let message;

    if (status === "I") {
      const contact = `${this.phoneNumber.areaCode}${this.phoneNumber.prefix}${this.phoneNumber.lineNumber}`
        + `@${this.serviceProvider}`;

      message = "You have chosen to Opt In to the Pre-Plan text messaging service using this phone info:<br>"
        + `<br>${contact}<br>`
        + "<br>You should receive a confirmation text message within the next 30 minutes.<br>"
        + "<br>If you do not receive the confirmation message, your account has not been verified. Return to this "
        + "website and review your phone number and service provider address, OR email txtalert@swifttrans.com for "
        + "assistance.";
    } else {
      message = "You have chosen to Opt OUT of the Pre-Plan text messaging service.";
    }

    this.showSuccessDialog(message);
  }

  async mounted() {
    await this.fetchDriverTextData();

    this.serviceProvider = this.carrierAddress;

    this.phoneNumber = {
      areaCode: this.areaCode,
      prefix: this.prefix,
      lineNumber: this.lineNumber,
    };
  }
}
</script>

<style scoped>
.current-status {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-top: 24px;
}

.instructions {
    text-align: center;
    font-size: 18px;
}

.blue-text {
    color: blue;
}

.red-text {
    color: red;
}

.action-button {
    width: 100%;
}
</style>
