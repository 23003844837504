<template>
  <div class="newDepositDialog">
    <v-dialog v-model="show" persistent width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          New Direct Deposit
        </v-card-title>
        <v-card-text class="text--center">
          <div class="mt-7">
            <v-alert v-if="noMoreDepositsAllowed" dense outlined type="error">
              You have the <strong>max</strong> number of Deposit splits.
            </v-alert>
            <v-row>
              <v-col>
                <v-radio-group label="Account Type" v-model="selectedOptions.accountType" :rules="[]">
                  <div v-for="accountType in accountTypes" :key="accountType.id">
                    <v-tooltip bottom :disabled="!accountType.disabled">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-radio
                            :value="accountType.id"
                            :label="accountType.name"
                            dense
                            hide-details
                            class="my-1"
                            :disabled="accountType.disabled"
                          />
                        </span>
                      </template>
                      <span>{{ accountType.tooltipText }}</span>
                    </v-tooltip>
                  </div>
                </v-radio-group>
              </v-col>
              <v-col>
                <v-radio-group :disabled="!accountTypeSelected" label="Deposit Type" v-model="selectedOptions.depositType" :rules="[]">
                  <div v-for="depositType in depositTypes" :key="depositType.id">
                    <v-tooltip bottom :disabled="!depositType.disabled">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-radio
                            :value="depositType.id"
                            :label="depositType.name"
                            :color="depositType.color"
                            dense
                            hide-details
                            :disabled="depositType.disabled"
                            class="my-1"
                          />
                        </span>
                      </template>
                      <span>{{ depositType.tooltipText }}</span>
                    </v-tooltip>
                  </div>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="red" text @click="$emit('cancel')"> Cancel </v-btn>
          <v-btn :disabled="showAddButton" color="red" text @click="$emit('addDirectDeposit', selectedOptions)">
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { getEnv } from "@/utils/util";

export default {
  data() {
    return {
      selectedOptions: {
        depositType: "",
        accountType: "",
      },
    };
  },
  methods: {
    getAccountTypes() {
      if (this.deposits) {
        const items = [
          {
            id: "C",
            name: "Checking",
            tooltipText: "You have maxed the allowed deposit amount.",
            disabled: this.noMoreDepositsAllowed,
          },
          {
            id: "S",
            name: "Savings",
            tooltipText: "You have maxed the allowed deposit amount.",
            disabled: this.noMoreDepositsAllowed,
          },
        ];
        if (this.isDriver) {
          items.push({
            id: "F",
            name: "Fuel",
            tooltipText: "You can have only 1 Fuel Card.",
            disabled: this.hasFuelCard,
          });
        }
        return items;
      }
      return [];
    },
    getDepositTypes() {
      if (this.deposits) {
        let items;
        if (!this.isDriver) {
          items = [
            {
              id: "F",
              name: "$ Dollar",
              color: "red",
              tooltipText: this.flatSplitLimitMessage,
              disabled:
                typeof this.flatSplitLimitMessage === "string"
                && !this.hasFuelCard,
            },
            {
              id: "P",
              name: "% Percentage",
              color: "blue",
              tooltipText: this.percentageSplitLimitMessage,
              disabled:
                typeof this.percentageSplitLimitMessage === "string"
                && !this.hasFuelCard,
            },
          ];
        } else {
          items = [
            {
              id: "F",
              name: "$ Dollar",
              tooltipText: this.flatSplitLimitMessage,
              color: "red",
              disabled: typeof this.flatSplitLimitMessage === "string",
            },
            {
              id: "P",
              name: "% Percentage",
              color: "blue",
              tooltipText: this.percentageSplitLimitMessage,
              disabled: typeof this.percentageSplitLimitMessage === "string",
            },
          ];
        }
        items.push({
          id: "NET",
          name: "Net",
          color: "",
          tooltipText: "You may only have 1 Net deposit split.",
          disabled: this.hasNet,
        });
        return items;
      }
      return [];
    },
  },
  computed: {
    ...mapState("payroll/directDeposit", ["deposits"]),
    ...mapGetters("auth", ["isDriver"]),
    ...mapGetters("payroll/directDeposit", [
      "validFlatSplitLimit",
      "validPercentageSplitLimit",
    ]),
    noMoreDepositsAllowed() {
      if (this.isDriver) {
        return (
          typeof this.percentageSplitLimitMessage === "string"
          && typeof this.flatSplitLimitMessage === "string"
          && this.hasNet
          && this.hasFuelCard
        );
      }
      return (
        typeof this.percentageSplitLimitMessage === "string"
          && typeof this.flatSplitLimitMessage === "string"
          && this.hasNet
      );
    },
    percentageSplitLimitMessage() {
      return (
        this.validPercentageSplitLimit
        || `You may only have ${getEnv("VUE_APP_MAX_PERCENTAGE_SPLITS")} checking or saving Percentage deposit splits.`
      );
    },
    flatSplitLimitMessage() {
      return (
        this.validFlatSplitLimit
        || `You may only have ${getEnv("VUE_APP_MAX_FLAT_SPLITS")} checking or saving Flat amount deposit splits.`
      );
    },
    accountTypes() {
      return this.getAccountTypes();
    },
    accountTypeSelected() {
      return this.selectedOptions.accountType !== "";
    },
    depositTypes() {
      if (!this.accountTypeSelected) {
        return this.getDepositTypes().map((depositType) => {
          depositType.disabled = true;
          depositType.tooltipText = "Select account type";
          return depositType;
        });
      }
      return this.getDepositTypes();
    },
    hasNet() {
      return !!this.deposits.find((deposit) => deposit.depositType === "NET");
    },
    hasFuelCard() {
      return !!this.deposits.find((deposit) => deposit.accountType === "F");
    },
    showAddButton() {
      return (
        this.selectedOptions.depositType === ""
        || this.selectedOptions.accountType === ""
      );
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.selectedOptions.depositType = "";
        this.selectedOptions.accountType = "";
      }
    },
  },
  props: {
    show: { type: Boolean, required: true },
  },
};
</script>

<style lang="scss" scoped></style>
