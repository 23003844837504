export default {
  setIsFetching(state, data) {
    state.isFetching = data;
  },
  setFetchError(state, data) {
    state.fetchError = data;
  },
  setIsSubmitting(state, data) {
    state.isSubmitting = data;
  },
  setSubmitError(state, data) {
    state.submitError = data;
  },
  setFormData(state, data) {
    state.optedIn = data.status === "I";
    state.carrierAddress = data.carrierAddr;
    state.areaCode = data.areaCode;
    state.prefix = data.prefix;
    state.lineNumber = data.lineNumber;
  },
};
