export default {
  setIsFetching(state, data) {
    state.isFetching = data;
  },
  setFetchError(state, data) {
    state.fetchError = data;
  },
  setIsSubmitting(state, data) {
    state.isSubmitting = data;
  },
  setSubmitError(state, data) {
    state.submitError = data;
  },
  setFormData(state, data) {
    state.hireDate = data.hireDate;
    state.isNewYork = data.newYork;
    state.payCycle = data.cycle;
    state.payType = data.exempt;
    state.payRate = data.payRate;
    state.rateSheet = data.rateSheet;
  },
};
