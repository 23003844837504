<template>
  <pageManager :isLoading="isFetching" :error="fetchError" :isEmpty="false">
    <v-container>
      <rate-sheet v-show="showRateSheet" :rateSheet="rateSheet" @close="closeRateSheet()" />
      <v-card elevation="2" v-show="!showRateSheet">
        <v-card-title class="headline white--text primary font-weight-light">
          Notice and Acknowledgement of Pay Rate and Payday
        </v-card-title>
        <v-card-text>
          <v-row class="mt-0">
            <v-col cols="12" class="form-title">
              Notice and Acknowledgement of Pay Rate and Payday Under Section 195.1 of the New York State Labor Law
            </v-col>
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols="12" lg="3">
              <div class="employerInfo">
                <p>1. Employer Information</p>
                <p>Name:<br>Swift Transportation</p>
                <p>Mailing Address:<br>P.O. Box 29243<br>Phoenix, AZ, 85038<br>Phone# 800-800-2200</p>
                <p>Physical New York Address:<br>7470 Round Pond Rd.<br>Syracuse, NY, 13212<br>Phone# 800-347-4485</p>
              </div>
              <div class="mt-6">
                <p>2. Notice Given:</p>
                <v-radio-group v-model="noticeGiven" class="mt-0" hide-details>
                  <v-radio label="Hire/Transfer" value="hire" />
                </v-radio-group>
              </div>
              <div class="mt-6">
                <p>3. Employee Pay Rate:</p>
                <p><span v-if="!hasRateSheet">$</span>{{ payRate }}</p>
                <a v-if="hasRateSheet" @click="openRateSheet()" @keydown.enter.stop="openRateSheet()">Driver Rates</a>
              </div>
              <div class="mt-6">
                <p>
                  3A. Students are paid a flat weekly or daily rate depending on the number of days worked within the
                  pay week or minimum wage as applicable.
                </p>
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="12" lg="3">
              <div>
                <p>4. Pay Day:</p>
                <v-radio-group v-model="payDay" class="mt-0" hide-details>
                  <v-radio label="Weekly on Friday for drivers" value="DRV" />
                  <v-radio label="Weekly on Thursday for NY-based technicians" value="NYSHP" />
                  <v-radio label="Bi-Weekly on Thursday for NY-based office employees (non-technicians)" value="OFC" />
                </v-radio-group>
              </div>
              <div class="mt-6">
                <p>5. Pay Type:</p>
                <v-radio-group v-model="payType" class="mt-0" hide-details>
                  <v-radio label="Exempt" value="S" />
                  <v-radio label="Non-Exempt" value="H" />
                </v-radio-group>
              </div>
              <div class="mt-6">
                <p>6. Allowances Taken:</p>
                <v-radio-group v-model="allowances" class="mt-0" hide-details>
                  <v-radio label="None" value="none" />
                  <v-radio label="Tips N/A per hour" value="tips" />
                  <v-radio label="Meals N/A per meal" value="meals" />
                  <v-radio label="Lodging N/A" value="lodging" />
                  <v-radio label="Other N/A" value="other" />
                </v-radio-group>
              </div>
              <div class="mt-6">
                <p>
                  7. Overtime Pay Rate:<br>
                  In most cases, the overtime rate will be 1 1/2 times the regular rate of pay for the week.
                  The regular rate of pay is the total weekly pay divided by the hours worked in the week.<br>
                  Pursuant to FLSA Section 13(b)(1) employees in safety-senstive functions(per DOT)
                  are exempt from overtime.<br><br>
                  In most cases, it is illegal to pay a fixed weekly rate for varying hours worked over 40 per week.
                  The Department of Labor strongly discourages weekly rates for non-exempt employees,
                  since under-payments often result.
                </p>
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="12" lg="3">
              <div>
                <p>
                  8. Employee Acknowledgement:<br>
                  On this day, I received notice of my pay rate, overtime rate(if eligible), and designated payday.
                  I told my employer what my primary language is.<br><br>Check one:
                </p>
                <v-radio-group v-model="isEnglishPrimary" class="mt-0" hide-details @change="clearLanguage()">
                  <v-radio
                    label="I have been given this pay notice in English because it is my primary language."
                    :value=true
                  />
                  <v-radio label="My primary language is:" :value="false" />
                </v-radio-group>
                <v-text-field
                  v-model="language"
                  :disabled="isEnglishPrimary === true || isEnglishPrimary === null"
                  outlined
                  class="mt-2"
                  clearable
                  counter
                  maxlength="30"
                />
              </div>
              <div class="mt-8">
                <v-row>
                  <v-col cols="12">
                    <p>Employee Name:</p>
                    <p>{{ currentUserFullName.toUpperCase() }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <p>Employee Number:</p>
                    <p>{{ currentUserEmployeeId }}</p>
                  </v-col>
                  <v-col cols="6">
                    <p>Last 4 SSN:</p>
                    <p>{{ currentUserSSNLast4 }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <p>Today's Date:</p>
                    <p>{{ today }}</p>
                  </v-col>
                  <v-col cols="6">
                    <p>Hire Date:</p>
                    <p>{{ hireDate }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <p>
                      The employee must receive a signed copy of this form.
                      The employer must keep the original for 6 years.<br><br>
                      <strong>Please note:</strong> It is unlawful for an employee to be paid less than an employee of
                      the opposite sex for equal work. Employers also may not prohibit employees from discussing wages
                      with their co-workers.
                    </p>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols="12" lg="9" xl="8">
              <v-checkbox
                v-model="isFormSigned"
                label="By clicking here I am providing an electronic signature confirming I acknowledge the
                  Application for Notice and Acknowledgement of Pay Rate and Payday under section 195.1 of the
                  New York Labor Law form. I understand the electronic signature is binding in the same manner
                  as a handwritten signature."
                hide-details
              />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols="12" lg="9" xl="8">
              <v-checkbox
                :disabled="!hasNotificationEmail"
                v-model="sendEmailNotification"
                :label="'Send me an email confirmation to: ' + notificationEmail"
                hide-details
              />
              <v-alert
                v-show="!hasNotificationEmail"
                outlined
                dense
                text
                type="warning"
              >
                <v-row>
                  <v-col cols="12" md="7">
                    You do not have a notification email on file. You can continue without an email confirmation, or
                    go to My Information to set a personal email address.
                  </v-col>
                  <v-col cols="12" md="5" class="d-flex align-center justify-center">
                    <v-btn color="warning" @click="openMyInfo()">Edit My Information</v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols="6" lg="4" xl="3">
              <v-btn
                color="primary"
                class="action-button"
                @click="submitForm('ACCEPTED')"
                :disabled="!isFormSigned || !isLanguageSet"
              >
                Accept
              </v-btn>
            </v-col>
            <v-col cols="6" lg="4" xl="3">
              <v-btn color="primary" class="action-button" @click="submitForm('DISCARDED')">Discard</v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <submitDialog :propIsSubmitting="isSubmitting" :propSubmitError="submitError" @clearErrors="clearErrors()" />
    <successDialog :message="successDialog.message" :show="successDialog.show" @close="closeSuccessDialog()" />
  </pageManager>
</template>

<script>
import { namespace } from "vuex-class";
import Component from "vue-class-component";
import pageManager from "@/components/pageManager.vue";
import { Mixins } from "vue-property-decorator";
import modulePageMixin from "@/utils/mixins";
import dates from "@/utils/dates";
import _ from "lodash";
import submitDialog from "@/components/submitDialog.vue";
import successDialog from "@/components/successDialog.vue";
import RateSheet from "@/modules/payroll/newYorkAcknowledgePayRate/rateSheet.vue";

const naprStore = namespace("payroll/newYorkAcknowledgePayRate");
const authStore = namespace("auth");

@Component({
  name: "NewYorkAcknowledgePayRate",
  components: {
    RateSheet, successDialog, submitDialog, pageManager,
  },
})
export default class NewYorkAcknowledgePayRate extends Mixins(modulePageMixin) {
  @naprStore.Action("fetchNAPRAccess") fetchNAPRAccess;

  @naprStore.Action("submitNAPRAcknowledgement") submitNAPRAcknowledgement;

  @naprStore.Getter("fetchError") fetchError;

  @naprStore.Getter("isSubmitting") isSubmitting;

  @naprStore.Getter("submitError") submitError;

  @naprStore.Getter("payRate") payRate;

  @naprStore.Getter("rateSheet") rateSheet;

  @naprStore.Getter("payCycle") payCycle;

  @naprStore.Getter("isNewYork") isNewYork;

  @naprStore.Getter("payType") payType;

  @naprStore.Getter("hireDate") hireDate;

  @authStore.Action("reloadUserInfo") reloadUserInfo;

  isFetching = false;

  showRateSheet = false;

  noticeGiven = "hire";

  allowances = "none";

  isEnglishPrimary = null;

  language = "";

  today = dates.todaysDate("MM/DD/YYYY");

  isFormSigned = false;

  sendEmailNotification = false;

  successDialog = {
    show: false,
    message: "Successfully Submitted Notice and Acknowledgement of Pay Rate and Payday Form!",
  };

  get hasRateSheet() {
    return !(_.isEmpty(this.rateSheet));
  }

  get payDay() {
    return this.isNewYork ? this.payCycle : "";
  }

  get isLanguageSet() {
    return this.isEnglishPrimary || !(_.isEmpty(this.language));
  }

  openRateSheet() {
    this.showRateSheet = true;

    this.$vuetify.goTo("#rateSheet", {
      duration: 500,
      offset: 1,
      easing: "linear",
    });
  }

  closeRateSheet() {
    this.showRateSheet = false;
  }

  clearLanguage() {
    this.language = "";
  }

  openMyInfo() {
    this.$router.push("/personal-info/my-information");
  }

  clearErrors() {
    this.setSubmitError({});
  }

  showSuccessDialog() {
    this.successDialog.show = _.isEmpty(this.submitError);
  }

  closeSuccessDialog() {
    this.successDialog.show = false;
    this.$router.push("/");
  }

  async submitForm(action) {
    const payload = {
      signed: this.isFormSigned,
      language: this.isEnglishPrimary ? "ENGLISH" : this.language,
      response: action,
      sendEmailNotification: this.sendEmailNotification,
    };

    await this.submitNAPRAcknowledgement(payload);

    this.showSuccessDialog();
  }

  async mounted() {
    this.isFetching = true;

    await this.reloadUserInfo();
    await this.fetchNAPRAccess();

    this.isFetching = false;

    this.sendEmailNotification = this.hasNotificationEmail;
  }
}
</script>

<style scoped>
p, a {
    font-size: 16px;
    margin-bottom: 0;
}

.form-title {
    text-align: center;
    font-size: 20px;
}

.employerInfo {
    border: 1px solid;
    max-width: 275px;
}

.employerInfo p {
    margin: 16px 4px;
}

.action-button {
    width: 100%;
}
</style>
