<template>
  <pageManager :isLoading="false" :error="''" :isEmpty="false">
    <v-container>
      <v-card elevation="2">
        <v-card-title class="headline white--text primary font-weight-light">
          Driver Text Opt In/Out - Disclaimer
        </v-card-title>
        <v-card-text>
          <v-row class="mt-1">
            <v-spacer />
            <v-col cols="12" md="10" lg="8" xl="6">
              <div class="disclaimer">
                <p>
                  By using this Web site, I understand and agree to the following:
                </p>
                <p>
                  Swift Transportation, Inc. has instituted a system whereby drivers may receive optional text message
                  notification of pre-planned loads in addition to the standard Qualcomm communication. By choosing to
                  opt into the pre-plan text messaging program, I understand that I am responsible for the accuracy of
                  the information I provide. Entry of my telephone information does not constitute a guarantee that I
                  will receive pre-plan text messages. Only the receipt of the system generated text confirmation
                  message ensures that my entries are correct and my service is working properly.
                </p>
                <p>
                  I understand that I am also responsible for any text messaging fees charged by my Service Provider and
                  for making any necessary updates to my phone number or Service Provider address.
                </p>
                <p>
                  Any choices I make on this Web site will be considered my written permission to Swift Transportation
                  to provide information or conduct transactions on my behalf in accordance with Swift Transportation's
                  employee policies, programs or employee benefit plans.
                </p>
                <p>
                  I am responsible for requesting and reviewing any confirmation statements made available to me (on
                  paper or in electronic form) regarding any instructions, choices or requests that I make through this
                  Web site. I am responsible for reviewing all of the information transmitted to me to ensure its
                  accuracy. If there are inaccuracies, I am responsible for correcting them using the tools available
                  to me on the Web site or by contacting the appropriate department to point them out.
                </p>
                <p>
                  The functionality of this Web site permits me to request or provide personal information. I am solely
                  responsible for any loss of privacy or confidentiality of this information if I disclose my user ID
                  and/or password to a third party.
                </p>
                <p>
                  I understand that this Web site may not perform as intended at all times. I agree that Swift
                  Transportation is not responsible for any error, omission, interruption or delay in operation or
                  transmission through this Web site, communication line failure, system failure, or other circumstances
                  beyond their control.
                </p>
              </div>
              <v-row class="mt-1">
                <v-spacer />
                <v-col cols="12" sm="6" md="4" class="text-center">
                  <v-btn class="action-button" color="primary" @click="acceptDisclaimer()">Accept</v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="text-center">
                  <v-btn class="action-button" color="primary" @click="returnToDashboard()">Back to Dashboard</v-btn>
                </v-col>
                <v-spacer />
              </v-row>
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </pageManager>
</template>

<script>
import Component from "vue-class-component";
import pageManager from "@/components/pageManager.vue";
import Vue from "vue";

@Component({
  name: "DriverTextDisclaimer",
  components: { pageManager },
})
export default class DriverTextDisclaimer extends Vue {
  acceptDisclaimer() {
    this.$emit("accept");
  }

  returnToDashboard() {
    this.$router.push("/");
  }
}
</script>

<style scoped>
.disclaimer {
    border: double;
    padding: 4px;
}

.disclaimer p:last-of-type {
    margin-bottom: 0;
}

.action-button {
    width: 200px;
}
</style>
