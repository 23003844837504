export default [
  {
    moduleId: "drivers",
    moduleName: "drivers",
    metaInfo: {
      title: "Drivers",
      meta: [
        {
          name: "Driver Services",
          content: "Drivers",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
    api: [{}],
  },
  {
    moduleId: "drivers:driverText",
    parent: "drivers",
    moduleName: "driverText",
    metaInfo: {
      title: "Driver Text Opt In/Out",
      meta: [
        {
          name: "driverText",
          content: "Driver Text Opt In/Out",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
  {
    moduleId: "drivers:smokingPreference",
    parent: "drivers",
    moduleName: "smokingPreference",
    metaInfo: {
      title: "Smoker/Non-Smoker Status",
      meta: [
        {
          name: "smokingPreference",
          content: "Smoker/Non-Smoker Status",
        },
      ],
    },
    forms: [
      {
        formId: "",
      },
    ],
    workflow: [],
  },
];
