<template>
  <v-card elevation="2">
    <v-card-title class="headline white--text primary font-weight-light" id="rateSheet">
      Driver Rates
      <v-btn outlined color="white" @click="$emit('close')">
        <v-icon left>mdi-keyboard-return</v-icon>
        Return
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div v-for="account in rateSheet" :key="account" class="mt-3 text-center">
        <v-row>
          <v-spacer />
          <v-col cols="6" md="4"><strong>Description:</strong><br>{{ account["description"] }}</v-col>
          <v-col cols="6" md="4"><strong>Effective Date:</strong><br>{{ account["effectiveDate"] }}</v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="3" md="3" class="pt-0 pb-0"><strong>Experience:</strong></v-col>
          <v-col cols="5" md="3" class="pt-0 pb-0"><strong>Rate:</strong></v-col>
          <v-col cols="4" md="3" class="pt-0 pb-0"><strong>Additional:</strong></v-col>
          <v-spacer />
        </v-row>
        <v-row v-for="detail in account['details']" :key="detail">
          <v-spacer />
          <v-col cols="3" md="3" class="pt-0 pb-0 p">
            <p>{{ detail["fromNumberMonths"] }} - {{ detail["toNumberMonths"] }} months<br><br><br><br></p>
          </v-col>
          <v-col cols="5" md="3" class="pt-0 pb-0">
            <p>
              West Coast Solo: {{ detail["westCoastSoloPerMile"] }}<br>
              West Coast Team: {{ detail["westCoastTeamPerMile"] }}<br>
              East Coast Solo: {{ detail["eastCoastSoloPerMile"] }}<br>
              East Coast Team: {{ detail["eastCoastTeamPerMile"] }}
            </p>
          </v-col>
          <v-col cols="4" md="3" class="pt-0 pb-0">
            <div v-if="detail['additionalPaySoloPerMile']">
              <p>
                Miles From: {{ detail["fromNumberMiles"] }} To: {{ detail["toNumberMiles"] }}<br>
                Solo: {{ detail["additionalPaySoloPerMile"] }}<br>
                Team: {{ detail["additionalPayTeamPerMile"] }}<br>
                Mile Type: {{ detail["basedOnMileType"] }}
              </p>
            </div>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-col cols="12" class="underline pt-0 pb-0 text-left">
            <div>
              <span v-for="comment in account['comments']" :key="comment">{{ comment }}</span>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "RateSheet",
})
export default class RateSheet extends Vue {
  @Prop({
    type: Array,
    default: [],
  })
    rateSheet;
}
</script>

<style scoped>
#rateSheet {
    display: flex;
    justify-content: space-between;
}

.underline {
    border-bottom: 2px solid;
}
</style>
