export default {
  allConfigs(state) {
    return state.config;
  },
  config: (state) => (key, defaultValue) => {
    if (!(key in state.config)) {
      console.warn(`Config does not contain "${key}"`);
      return defaultValue;
    }
    return state.config[key];
  },
};
