export default {
  setIsFetching(state, data) {
    state.isFetching = data;
  },
  setFetchError(state, data) {
    state.fetchError = data;
  },
  setIsSubmitting(state, data) {
    state.isSubmitting = data;
  },
  setSubmitError(state, data) {
    state.submitError = data;
  },
  setAllowSharing(state, data) {
    if (data === "Y") {
      state.allowSharing = false;
    }
    if (data === "N") {
      state.allowSharing = true;
    }
  },
};
