import charitiesAPI from "@/modules/payroll/api/charities";

export default {
  async fetchCharityData({ commit }) {
    commit("setIsFetching", true);
    commit("setFetchError", null);

    try {
      const { data } = await charitiesAPI.fetchCharityData();

      const { amounts } = data.charities;

      commit("setAmounts", amounts);
    } catch (error) {
      if (error.response) {
        commit("setFetchError", error.response.data);
      } else if (error.request) {
        commit("setFetchError", {
          title: "Bad Request",
          status: "",
          detail: "Could not complete this request",
        });
      } else {
        commit("setFetchError", "Unknown error");
      }
    } finally {
      commit("setIsFetching", false);
    }
  },
  async submitCharitySelections({ commit }, payload) {
    commit("setIsSubmitting", true);
    commit("setSubmitError", {});

    try {
      await charitiesAPI.submitCharitySelections(payload);
    } catch (error) {
      if (error.response) {
        commit("setSubmitError", error.response.data);
      } else if (error.request) {
        commit("setSubmitError", {
          title: "Bad Request",
          status: "",
          detail: "Could not complete this request",
        });
      } else {
        commit("setSubmitError", "Unknown error");
      }
    } finally {
      commit("setIsSubmitting", false);
    }
  },
};
