import Vue from "vue";

export default {
  authToken(state) {
    return state.token;
  },
  employeeId(state) {
    return state.userInfo?.employeeId;
  },
  employerId(state) {
    return state.userInfo?.employerId;
  },
  userId(state) {
    return state.userInfo?.userId;
  },
  firstName(state) {
    return state.userInfo?.firstName;
  },
  lastName(state) {
    return state.userInfo?.lastName;
  },
  companyEmailAddress(state) {
    return state.userInfo?.email;
  },
  personalEmailAddress(state) {
    return state.userInfo?.emailAddress2;
  },
  employeeSsn(state) {
    return state.userInfo?.employeeSsnText;
  },
  isDriver(state) {
    return state.userInfo?.level2.toLowerCase().indexOf("drv") !== -1;
  },
  isNonDriver(state) {
    return state.userInfo?.level2.toLowerCase().indexOf("drv") === -1;
  },
  isKEX(state) {
    return state.userInfo?.employerId === "KEX";
  },
  userRoles(state) {
    return state.userInfo?.userRoles ?? {};
  },
  isManager(state) {
    return state.userInfo?.userRoles?.isManager ?? false;
  },
  isSupervisor(state) {
    return state.userInfo?.userRoles?.isSupervisor ?? false;
  },
  isPayrollAdmin(state) {
    return state.userInfo?.userRoles?.isPayrollAdmin ?? false;
  },
  isPayroll(state) {
    return state.userInfo?.userRoles?.isPayroll ?? false;
  },
  isPayrollAssistant(state) {
    return state.userInfo?.userRoles?.isPayrollAssistant ?? false;
  },
  isProxy(state) {
    return state.userInfo?.userRoles?.isProxy ?? false;
  },
  isProxyForManager(state) {
    return state.userInfo?.userRoles?.isProxyForManager ?? false;
  },
  managerProxyAssignments(state) {
    return state.userInfo?.managerProxyAssignments ?? [];
  },
  hasAnyRole: (state) => (roles) => {
    if (roles !== undefined) {
      return roles.reduce((carry, role) => {
        if (carry === true) {
          return true;
        }
        return state.userInfo.userRoles[role] ?? false;
      }, false);
    }
    return false;
  },
  hasPermission: (state) => (permissions) => {
    if (permissions === undefined) return false;

    // empty permissions array is ok
    if (Array.isArray(permissions) && permissions.length === 0) {
      return true;
    }

    return permissions.reduce((carry, permission) => {
      if (carry === true) {
        return true;
      }
      return state.userInfo?.permissions[permission] ?? false;
    }, false);
  },
  shouldExclude: (state, getters) => (userTypes) => {
    let shouldExclude = false;

    if (userTypes !== undefined) {
      userTypes.forEach((userType) => {
        if (getters[userType]) {
          shouldExclude = true;
        }
      });
    }

    return shouldExclude;
  },
  userFullName(state) {
    const titleCase = (value) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

    return (
      `${titleCase(state.userInfo?.firstName.trim()) ?? ""} ${titleCase(state.userInfo?.lastName.trim()) ?? ""}`
    );
  },
  getLoggedInUser(state) {
    return (
      `${state.userInfo?.firstName.trim() ?? ""} ${state.userInfo?.lastName.trim() ?? ""} (${state?.userId})`
    );
  },
  isFullyLoggedIn(state) {
    return (
      state.isLoggedIn
      && (state.ssoLogin || (!Vue.$appInfo.requireMultiFactorLogin || state.isMultiFactorValidated))
    );
  },
  getLoginRedirect(state) {
    return state.loginRedirect;
  },
  hasAuthInSessionStorage() {
    return (
      sessionStorage.getItem("jwt")
      && (!Vue.$appInfo.requireMultiFactorLogin
        || sessionStorage.getItem("isMultiFactorValidated"))
    );
  },
};
