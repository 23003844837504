import Vue from "vue";
import stateTax from "./stateTax";
import emailPreferencesAPI from "./emailPreferences";
import directDepositAPI from "./directDeposit";
import milesBasedPayAcknowledgeAPI from "./milesBasedPayAcknowledge";
import w4 from "./w4";
import employeeStockPurchaseAPI from "./employeeStockPurchase";
import charitiesAPI from "./charities";
import californiaMealBreakWaiverAPI from "./californiaMealBreakWaiver";
import newYorkAcknowledgePayRateAPI from "./newYorkAcknowledgePayRate";

export default {
  getApi() {
    return {
      emailPreferencesAPI,
      stateTax,
      directDepositAPI,
      milesBasedPayAcknowledgeAPI,
      employeeStockPurchaseAPI,
      charitiesAPI,
      californiaMealBreakWaiverAPI,
      newYorkAcknowledgePayRateAPI,
    };
  },
  registerApi() {
    Vue.$apis.addApi(stateTax);
    Vue.$apis.addApi(emailPreferencesAPI);
    Vue.$apis.addApi(directDepositAPI);
    Vue.$apis.addApi(milesBasedPayAcknowledgeAPI);
    Vue.$apis.addApi(w4);
    Vue.$apis.addApi(employeeStockPurchaseAPI);
    Vue.$apis.addApi(charitiesAPI);
    Vue.$apis.addApi(californiaMealBreakWaiverAPI);
    Vue.$apis.addApi(newYorkAcknowledgePayRateAPI);
  },
};
